import {
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Table,
  Form,
} from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { url }  from "../../constant/url";
import moment from "moment";
import { DeleteOutlined, BarcodeOutlined } from "@ant-design/icons";

function StockOutTest(history) {
  const { Search } = Input;
  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      sorter: true,
    },
    {
      dataIndex: "date",
      title: " Date",
      key: "date",
      render: (date) => moment(date).format("DD MMMM YYYY"),
    },
    {
      title: "Item",
      dataIndex: "item_name",
      sorter: false,
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "are",
      sorter: false,
    },
    {
      title: "Std Stcok",
      dataIndex: "std_stock",
      key: "std_stock",
      sorter: false,
    },
    {
      title: "Maker",
      dataIndex: "vendor_name",
      key: "vendor_name",
      sorter: false,
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: false,
    },
    {
      title: "Used",
      dataIndex: "used",
      key: "used",
      sorter: false,
    },
    {
      title: "Status Part",
      dataIndex: "status_part",
      key: "status_part",
      sorter: false,
    },
    {
      title: "Warehouse",
      dataIndex: "warehouse_name",
      sorter: false,
    },
    {
      title: "Rack",
      dataIndex: "rack_name",
      sorter: false,
    },
    {
      title: "Lemari",
      dataIndex: "lemari_name",
      sorter: false,
    },
    {
      title: "",
      dataIndex: "_id",
      key: "id",
      sorter: false,
      fixed: "right",
      width: 50,
      render: (id) => {
        return (
          <>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <span onClick={() => handleDelete(id)}>
                      <DeleteOutlined />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <i className="mdi mdi-dots-vertical" />
              </span>
            </Dropdown>
          </>
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [setScanKanban] = useState([]);
  const [scansWarehouse, setScanWarehouse] = useState([]);
  const [total, setTotal] = useState([]);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [sort, setSort] = useState({
    sort: "code",
    order: "DESC",
  });
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [type, setType] = useState("stock out");
  const kanbanRef = useRef(null);

  const [scan, setScan] = useState({
    lemari: "",
    kanban: "",
  });

  const [isDisabled, setIsDisabled] = useState({
    lemari: false,
    kanban: true,
  });

  useEffect(() => {
    getData();
  }, [keyword, page, perpage, sort, type]);

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/transaction`, {
        params: {
          ket: type,
          limit: perpage,
          page,
          sortBy: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure ?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    axios
      .delete(`${url}/transaction/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPerpage(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeSearchWarehouse = async (value) => {
    await axios
      .get(`${url}/mapping-warehouse/code/${value}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setScanWarehouse(res.data.data);
        setIsDisabled({
          lemari: true,
          kanban: false,
        });
        kanbanRef.current.focus();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {});
  };

  const handleChangeSearchKanban = async (value) => {
    await axios
      .get(`${url}/item/code/${value}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setScanKanban(res.data.data);
        axios
          .post(
            `${url}/transaction`,
            {
              code: res.data.data.code,
              date: moment().format("DD MMMM YYYY"),
              item_id: res.data.data._id,
              item_name: res.data.data.name,
              area: res.data.data.area,
              std_stock: res.data.data.std_stock,
              vendor_id: res.data.data.vendor_id,
              vendor_name: res.data.data.vendor_name,
              quantity: res.data.data.quantity,
              quantity_update: res.data.data.quantity_update,
              type: res.data.data.type,
              used: res.data.data.used,
              status_part: res.data.data.status_part,
              warehouse_id: scansWarehouse.warehouse_id,
              warehouse_code: scansWarehouse.warehouse_code,
              warehouse_name: scansWarehouse.warehouse_name,
              rack_id: scansWarehouse.rack_id,
              rack_name: scansWarehouse.rack_name,
              lemari_id: scansWarehouse.lemari_id,
              lemari_code: scansWarehouse.lemari_code,
              lemari_name: scansWarehouse.lemari_name,
              ket: "stock out",
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            message.success(res.data.message);
            getData();
          })
          .catch((err) => {
            if (err.response) {
              message.error(err.response.data.message);
            }
          })
          .finally(() => {});
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {});
  };

  const handleReset = () => {
    setData({
      code: "",
    });
  };

  return (
    <div>
      <ul className="breadcumb">
        <li className="active">Stock Out</li>
      </ul>
      <div className="content">
        <Row justify="space-between">
          <Col span={14}>
            <Form.Item>
              <Search
                disabled={isDisabled.lemari}
                allowClear="true"
                height="1000px"
                size="middle"
                value={scan.lemari}
                onChange={(e) => setScan({ ...scan, lemari: e.target.value })}
                style={{ marginLeft: "150px" }}
                onSearch={(value) => handleChangeSearchWarehouse(value)}
                placeholder="Scan Warehouse ID"
                prefix={<BarcodeOutlined />}
              />
            </Form.Item>
            <Form.Item>
              <Search
                disabled={isDisabled.kanban}
                ref={kanbanRef}
                value={scan.kanban}
                onChange={(e) => setScan({ ...scan, kanban: e.target.value })}
                allowClear="true"
                height="1000px"
                size="middle"
                style={{ marginLeft: "150px" }}
                onSearch={(value) => handleChangeSearchKanban(value)}
                placeholder="Scan Item Code"
                prefix={<BarcodeOutlined />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="mt-40">
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={data}
              pagination={{ total, showSizeChanger: true }}
              loading={loading}
              rowKey={(data) => data._id}
              onChange={handleTableChange}
              style={{ maxWidth: 900 }}
              scroll={{ x: 2500 }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default StockOutTest;
