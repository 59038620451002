import { Button, Col, Input, message, Row, Select, Form, Upload, List } from "antd";
import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import "./UserForm.css";
import axios from "axios";
import { url }  from "../../constant/url";
import {
  UploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const { Option } = Select;

function UserForm({ history, match }) {
  const [data, setData] = useState({
    username: "",
    full_name: "",
    password: "",
    password_confirmation: "",
    role_id: "",
    role_name: "",
    photo: null,
    photo_url: "",
    permissions: [],
  });

  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (match.params.id) {
      getUser();
    }
  }, []);

  const handleChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeSelect = (name, value) => {
    setErrors(null);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_name`]: value.label,
    });
  };

  const getUser = () => {
    setLoading(true);
    axios
      .get(`${url}/user/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const {
          username,
          full_name,
          password,
          password_confirmation,
          photo_url,
          role_id,
          role_name,
          permissions,
        } = res.data.data;
        setData({
          ...data,
          username,
          full_name,
          password,
          password_confirmation,
          photo_url,
          role_id,
          role_name,
          permissions,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      username: "",
      full_name: "",
      password: "",
      password_confirmation: "",
      role_id: "",
      role_name: "",
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(data.role);
    let fd = new FormData();
    
    fd.set("full_name", data.full_name);
    fd.set("username", data.username );
    fd.set("password", data.password ? data.password : null );
    fd.set("password_confirmation", data.password_confirmation ? data.password_confirmation : null);
    fd.set("role_id", data.role_id);
    fd.set("role_id", data.role_id);
    fd.set("role_name", data.role_name);
    fd.append("photo", data.photo);

    console.log(fd)
    axios.post(
        match.params.id ? `${url}/user/${match.params.id}` : `${url}/user`, fd ,
        // {
        //   // full_name: data.full_name,
        //   // username: data.username,
        //   // password: data.password,
        //   // password_confirmation: data.password_confirmation,
        //   // role_id: data.role_id,
        //   // role_name: data.role_name,
        //   // photo: data.photo
        // },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/user");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  const getRole = async (keyword) => {
    await axios
      .get(`${url}/role`, {
        params: {
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setRoles(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleRemove = () => {
    setData({
      ...data,
      photo: null,
    });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      photo: files,
    });

    return false;
  };

  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/user">User</Link>
        </li>
        <li className="active">{match.params.id ? "Edit User" : "Add User"}</li>
      </ul>
      <div className="content">
        <form onSubmit={handleSave}>
          <Row gutter={20}>
            <Col md={18} xs={24}>
              <div className="form-group">
                <label className="form-label">
                  Full Name <span className="error-text">*</span>
                </label>
                <Input
                  value={data.full_name}
                  className={errors && errors.full_name ? "is-error" : ""}
                  onChange={(e) => handleChange("full_name", e.target.value)}
                />
                {errors && errors.full_name && (
                  <span className="error-text">{errors.full_name[0]}</span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">
                  Username <span className="error-text">*</span>
                </label>
                <Input
                  value={data.username}
                  className={errors && errors.username ? "is-error" : ""}
                  onChange={(e) => handleChange("username", e.target.value)}
                />
                {errors && errors.username && (
                  <span className="error-text">{errors.username[0]}</span>
                )}
              </div>

              <div className="form-group">
                <label className="form-label">
                  Password
                  {!match.params.id && <span className="error-text">*</span>}
                </label>
                <Input
                  className={errors && errors.password ? "is-error" : ""}
                  onChange={(e) => handleChange("password", e.target.value)}
                  type="password"
                />
                {errors && errors.password && (
                  <span className="error-text">{errors.password[0]}</span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">
                  Retype Password
                  {!match.params.id}
                </label>
                <Input.Password
                  value={data.password_confirmation}
                  onChange={(e) =>
                    handleChange("password_confirmation", e.target.value)
                  }
                />
              </div>

              <div className="form-group">
                Role <span className="error-text">*</span>
                <Select
                  style={{ width: 885 }}
                  labelInValue
                  onChange={(value) =>
                    handleChangeSelect("role", value, data._id)
                  }
                  onFocus={() => getRole("")}
                  showSearch
                  onSearch={(value) => getRole(value)}
                  filterOption={false}
                  value={{
                    key: data.role_id,
                    label: data.role_name,
                  }}
                >
                  {roles &&
                    roles.map((role) => {
                      return (
                        <Option value={role._id} key={role._id}>
                          {role.name}
                        </Option>
                      );
                    })}
                </Select>
                {errors && errors.role_name && (
                  <span className="error-text">{errors.role_name[0]}</span>
                )}
              </div>

              <Form.Item name="photo" label="Photo">
                <Fragment>
                  <Upload
                    onRemove={handleRemove}
                    beforeUpload={handleBeforeUpload}
                    fileList={[]}
                  >
                    <Button>
                      Upload <UploadOutlined />
                    </Button>
                  </Upload>
                  {data.photo && (
                    <List>
                      <List.Item
                        actions={[
                          <Button
                            onClick={handleRemove}
                            type="danger"
                            ghost
                          >
                            <DeleteOutlined />
                          </Button>,
                        ]}
                      >
                        <List.Item.Meta description={data.photo.name} />
                      </List.Item>
                    </List>
                  )}
                </Fragment>
              </Form.Item>

              <div className="form-group">
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{ backgroundColor: "#069964", borderColor: "#069964" }}
                >
                  Submit
                </Button>
                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  style={{
                    backgroundColor: "#069964",
                    marginLeft: 10,
                    borderColor: "#069964",
                  }}
                >
                  Reset
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default UserForm;
