import React from "react";
import BaseLayout from "./components/BaseLayout/BaseLayout";
import FullLayout from "./components/FullLayout/FullLayout";
import Login from "./pages/Login/Login";
import { Redirect } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import User from "./pages/User/User";
import UserForm from "./pages/User/UserForm";
import Item from "./pages/Item/Item";
import ItemForm from "./pages/Item/ItemForm";
import Vendor from "./pages/Vendor/Vendor";
import VendorForm from "./pages/Vendor/VendorForm";
import Kanban from "./pages/Kanban/Kanban";
import KanbanForm from "./pages/Kanban/KanbanForm";
import Warehouse from "./pages/Warehouse/Warehouse";
import WarehouseForm from "./pages/Warehouse/WarehouseForm";
import Role from "./pages/Role/Role";
import RoleForm from "./pages/Role/RoleForm";
import StockIn from "./pages/StockIn/StockIn";
import StockInForm from "./pages/StockIn/StockInForm";
import StockOUt from "./pages/StockOut/StockOut";
import StockOutForm from "./pages/StockOut/StockOutForm";
import StockOpname from "./pages/StockOpname/StockOpname";
import StockOpnameForm from "./pages/StockOpname/StockOpnameForm";
import Permission from "./pages/Permission/Permission";
import PermissionForm from "./pages/Permission/PermissionForm";
import Menu from "./pages/Menu/Menu";
import ReportMutasi from "./pages/ReportMutasi/ReportMutasi";
import Setting from "./pages/Setting/Setting";
import SettingForm from "./pages/Setting/SettingForm";
import StockOutTest from "./pages/StockOutTest/StockOutTest";

const router = [
  {
    path: "/",
    layout: BaseLayout,
    component: () => <Redirect to="/login" />,
  },
  {
    path: "/dashboard",
    layout: BaseLayout,
    component: Dashboard,
  },
  {
    path: "/user",
    layout: BaseLayout,
    component: User,
  },
  {
    path: "/user/create",
    layout: BaseLayout,
    component: UserForm,
  },
  {
    path: "/user/edit/:id",
    layout: BaseLayout,
    component: UserForm,
  },

  {
    path: "/login",
    layout: FullLayout,
    component: Login,
  },
  {
    path: "/item",
    layout: BaseLayout,
    component: Item,
  },
  {
    path: "/item/create",
    layout: BaseLayout,
    component: ItemForm,
  },
  {
    path: "/item/edit/:id",
    layout: BaseLayout,
    component: ItemForm,
  },
  {
    path: "/vendor",
    layout: BaseLayout,
    component: Vendor,
  },
  {
    path: "/vendor/create",
    layout: BaseLayout,
    component: VendorForm,
  },
  {
    path: "/vendor/edit/:id",
    layout: BaseLayout,
    component: VendorForm,
  },
  {
    path: "/kanban",
    layout: BaseLayout,
    component: Kanban,
  },
  {
    path: "/kanban/create",
    layout: BaseLayout,
    component: KanbanForm,
  },
  {
    path: "/kanban/edit/:id",
    layout: BaseLayout,
    component: KanbanForm,
  },
  {
    path: "/warehouse",
    layout: BaseLayout,
    component: Warehouse,
  },
  {
    path: "/warehouse/create",
    layout: BaseLayout,
    component: WarehouseForm,
  },
  {
    path: "/warehouse/edit/:id",
    layout: BaseLayout,
    component: WarehouseForm,
  },
  {
    path: "/role",
    layout: BaseLayout,
    component: Role,
  },
  {
    path: "/role/create",
    layout: BaseLayout,
    component: RoleForm,
  },
  {
    path: "/role/edit/:id",
    layout: BaseLayout,
    component: RoleForm,
  },
  {
    path: "/stock-in",
    layout: BaseLayout,
    component: StockIn,
  },
  {
    path: "/stock-in/create",
    layout: BaseLayout,
    component: StockInForm,
  },
  {
    path: "/stock-in/edit/:id",
    layout: BaseLayout,
    component: StockInForm,
  },
  {
    path: "/stock-out",
    layout: BaseLayout,
    component: StockOUt,
  },
  {
    path: "/stock-out/create",
    layout: BaseLayout,
    component: StockOutForm,
  },
  {
    path: "/stock-out/edit/:id",
    layout: BaseLayout,
    component: StockOutForm,
  },
  {
    path: "/stock-out-test",
    layout: BaseLayout,
    component: StockOutTest,
  },
  {
    path: "/stock-opname",
    layout: BaseLayout,
    component: StockOpname,
  },
  {
    path: "/stock-opname/create",
    layout: BaseLayout,
    component: StockOpnameForm,
  },
  {
    path: "/stock-opname/edit/:id",
    layout: BaseLayout,
    component: StockOpnameForm,
  },
  {
    path: "/permission",
    layout: BaseLayout,
    component: Permission,
  },
  {
    path: "/permission/create",
    layout: BaseLayout,
    component: PermissionForm,
  },
  {
    path: "/permission/edit/:id",
    layout: BaseLayout,
    component: PermissionForm,
  },
  {
    path: "/menu",
    layout: BaseLayout,
    component: Menu,
  },
  {
    path: "/report-mutasi",
    layout: BaseLayout,
    component: ReportMutasi,
  },
  {
    path: "/setting",
    layout: BaseLayout,
    component: Setting,
  },
  {
    path: "/setting/create",
    layout: BaseLayout,
    component: SettingForm,
  },
  {
    path: "/setting/edit/:id",
    layout: BaseLayout,
    component: SettingForm,
  },
];

export default router;
