import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Table,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { url }  from "../../constant/url";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";

function Permission() {
  const columns = [
    {
      title: "Permission",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: false,
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      sorter: false,
    },
    {
      title: "Parent Name",
      dataIndex: "parent_name",
      key: "parent_name",
      sorter: false,
    },

    {
      title: "",
      dataIndex: "_id",
      key: "_id",
      sorter: false,
      fixed: "right",
      width: 50,
      render: (id) => {
        return (
          <>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link to={`/permission/edit/${id}`}>
                      <EditOutlined /> Edit
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <span onClick={() => handleDelete(id)}>
                      <DeleteOutlined />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <i className="mdi mdi-dots-vertical" />
              </span>
            </Dropdown>
          </>
        );
      },
    },
  ];

  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [pageSize, setPagesize] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [permission, setPermission] = useState([]);

  useEffect(() => {
    getData();
  }, [keyword, page, perpage, sort]);

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/permission`, {
        params: {
          keyword,
          columns: ["name"],
          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setPermission(res.data);
      })
      .catch((error) => {
        if (error.response) {
          message.error(error.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure ?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    axios
      .delete(`${url}/permission/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPagesize(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  return localStorage.getItem("token") ? (
    <div>
      <ul className="breadcumb">
        <li className="active">Permission</li>
      </ul>
      <div className="content">
        <Row justify="space-between">
          <Col>
            <Link to="/permission/create">
              <Button
                type="primary"
                style={{ backgroundColor: "#069964", borderColor: "#069964" }}
              >
                <PlusOutlined />
                Add
              </Button>
            </Link>
          </Col>
          <Col>
            <Input.Search
              onSearch={(value) => setKeyword(value)}
              placeholder="Search..."
            />
          </Col>
        </Row>
        <Row className="mt-40">
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={permission.data}
              pagination={{
                pageSize: pageSize,
                current: page,
                total: Permissions.total,
                showSizeChanger: true,
              }}
              loading={loading}
              rowKey={(data) => data._id}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </div>
    </div>
  ) : (
    <Redirect to="/login" />
  );
}

export default Permission;
