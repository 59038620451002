import {
  Button,
  Col,
  message,
  Modal,
  Row,
  Table,
  Upload,
  Space,
  Select,
  Avatar,
  Card,
} from "antd";
import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { url }  from "../../constant/url";
import profile from "../../assets/item.png";
import {
  ExportOutlined,
  ImportOutlined,
  DeleteOutlined,
  FileImageOutlined,
} from "@ant-design/icons";
import fileDownload from "js-file-download";

const { Option } = Select;

function StockOpnameForm({ history, match }) {
  const columns = [
    {
      title: "Item  ",
      dataIndex: "name",
      sorter: false,
    },
    {
      title: "Type ",
      dataIndex: "type",
      sorter: false,
    },
    {
      title: "Stock Min ",
      dataIndex: "stock_min",
      sorter: false,
    },
    {
      title: " Stock Max ",
      dataIndex: "stock_max",
      sorter: false,
    },
    {
      title: "Warehouse ",
      dataIndex: "warehouse_name",
      sorter: false,
    },
    {
      title: "Lemari ",
      dataIndex: "lemari_name",
      sorter: false,
    },
    {
      title: "Rack ",
      dataIndex: "rack_name",
      sorter: false,
    },
    
    {
      title: "Qty System ",
      dataIndex: "quantity",
      sorter: false,
    },
    {
      title: "Qty Actual",
      dataIndex: "quantity_stock_opname",
      sorter: false,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      sorter: false,
    },
  ];

  const [data, setData] = useState({ file: null });
  const [stockopname, setStockOpname] = useState([]);
  const [total, setTotal] = useState([]);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [warehouses, setWarehouses] = useState([]);
  const [racks, setRacks] = useState([]);
  const [lemaris, setLemaris] = useState([]);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [filter, setFilter] = useState({
    warehouse_id: "",
    warehouse_name: "",
    lemari_id: "",
    lemari_name: "",
    rack_id: "",
    rack_name: "",
  });

  useEffect(() => {
    getData();
  }, [keyword, page, perpage, sort, filter]);

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/stock-opname/list-index`, {
        params: {
          keyword,
          warehouse_id: filter.warehouse_id,
          lemari_id: filter.lemari_id,
          rack_id: filter.rack_id,
          perpage: perpage,
          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setData(res.data.data);
        setStockOpname(res.data);
        setTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col className="gutter-row" span={6}>
        <div>
          <Button
            onClick={showModal}
            type="primary"
            style={{
              backgroundColor: "#069964",
              borderColor: "#069964",
              marginLeft: 10,
              marginTop: 20,
            }}
          >
            <ImportOutlined />
            Import
          </Button>
        </div>
      </Col>
      <Col className="gutter-row" span={6}>
        <div>
          <Button
            onClick={downloadTemplate}
            type="primary"
            style={{
              backgroundColor: "#069964",
              borderColor: "#069964",
              marginLeft: -120,
              marginTop: 20,
            }}
          >
            <ExportOutlined />
            Export
          </Button>
        </div>
      </Col>
    </Row>;
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPerpage(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const getWarehouse = async (keyword, type) => {
    await axios
      .get(`${url}/warehouse/list`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setWarehouses(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {});
  };

  const handleChangeViewWarehouse = (name, value) => {
    setErrors(null);

    if (value) {
      setFilter({
        ...filter,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setFilter({
        ...filter,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const getRack = async (keyword, type) => {
    await axios
      .get(`${url}/rack/list`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setRacks(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {});
  };

  const handleChangeViewRack = (name, value) => {
    setErrors(null);

    if (value) {
      setFilter({
        ...filter,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setFilter({
        ...filter,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const getLemari = async (keyword, type) => {
    await axios
      .get(`${url}/lemari/list`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLemaris(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {});
  };

  const handleChangeViewLemari = (name, value) => {
    setErrors(null);

    if (value) {
      setFilter({
        ...filter,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setFilter({
        ...filter,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const downloadTemplate = () => {
    setLoading(true);
    axios
      .get(`${url}/stock-opname/download`, {
        params: {
          keyword,
          warehouse_id: filter.warehouse_id,
          lemari_id: filter.lemari_id,
          rack_id: filter.rack_id,
          perpage: perpage,
          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, "export_stock_opname.xlsx");
      });
    setLoading(false);
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleRemoveEvidence = () => {
    setData({
      ...data,
      images: null,
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);
    fd.append("image", data.images);

    axios
      .post(`${url}/stock-opname/import`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setErrors(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const handleBeforeUploadEvidence = (file) => {
    setData({
      ...data,
      images_url: "",
      images: file,
    });

    return false;
  };

  const handleReset = () => {
    setData({
      file: "",
      images: "",
    });
  };

  const showTotal = (total) => {
    return `Found total ${total} record`;
  };

  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/stock-opname">Stock Opname</Link>
        </li>
        <li className="active"> Stock Opname Form</li>
      </ul>

      <Modal
        title="Import Stock Opname"
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
        // onCancel={handleCancel}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Fragment>
                <Upload
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  fileList={[]}
                >
                  <Button style={{ marginBottom: 10 }}>
                    Choose File Excel <ExportOutlined />
                  </Button>
                </Upload>
                {data.file && (
                  <React.Fragment
                    children={[
                      <Button onClick={handleRemove} type="danger" ghost>
                        <DeleteOutlined />
                      </Button>,
                    ]}
                  >
                    {data.file.name ? data.file.name : data.file}
                  </React.Fragment>
                )}
              </Fragment>
            </div>
            <div name="file" label="File" style={{ marginTop: 20 }}>
              <Fragment>
                <div className="mb-20">
                  <Avatar
                    src={
                      data.images_url
                        ? data.images_url
                        : data.images
                        ? URL.createObjectURL(data.images)
                        : profile
                    }
                    size={50}
                  />
                </div>
                <Upload
                  onRemove={handleRemoveEvidence}
                  beforeUpload={handleBeforeUploadEvidence}
                  fileList={[]}
                  accept="image/jpg, image/png"
                >
                  {data.images ? (
                    <Button
                      style={{ marginBottom: 10 }}
                      type="danger"
                      onClick={handleRemoveEvidence}
                    >
                      <i className="mdi mdi-close mr-5" />
                      Delete Evidence
                    </Button>
                  ) : (
                    <Button style={{ marginBottom: 10 }}>
                      Choose File Evidence <FileImageOutlined />
                    </Button>
                  )}
                </Upload>
              </Fragment>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSaveData}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                  backgroundColor: "#069964",
                  borderColor: "#069964",
                }}
              >
                Upload
              </Button>
              <Button htmlType="reset" onClick={handleReset}>
                Reset
              </Button>
            </div>
          </div>
        </Card>
      </Modal>

      <div className="content">
        <Col className="align-right">
          <Row type="flex" gutter={[12, 24]} justify="space-around">
            <Col></Col>

            <Space direction="vertical" size={12}>
              <Select
                allowClear="true"
                placeholder=" Warehouse"
                style={{ width: 265 }}
                labelInValue
                onChange={(value) =>
                  handleChangeViewWarehouse("warehouse", value)
                }
                onFocus={() => getWarehouse("")}
                showSearch
                onSearch={(value) => getWarehouse(value)}
                filterOption={false}
              >
                {warehouses &&
                  warehouses.map((warehouse) => {
                    return (
                      <Option value={warehouse._id} key={warehouse.name}>
                        {warehouse.name}
                      </Option>
                    );
                  })}
              </Select>
            </Space>
            <Space direction="vertical" size={12}>
              <Select
                allowClear="true"
                placeholder=" Lemari"
                style={{ width: 265 }}
                labelInValue
                onChange={(value) => handleChangeViewLemari("lemari", value)}
                onFocus={() => getLemari("")}
                showSearch
                onSearch={(value) => getLemari(value)}
                filterOption={false}
              >
                {lemaris &&
                  lemaris.map((lemari) => {
                    return (
                      <Option value={lemari._id} key={lemari.name}>
                        {lemari.name}
                      </Option>
                    );
                  })}
              </Select>
            </Space>
            <Space direction="vertical" size={12}>
              <Select
                allowClear="true"
                placeholder=" Rack"
                style={{ width: 265 }}
                labelInValue
                onChange={(value) => handleChangeViewRack("rack", value)}
                onFocus={() => getRack("")}
                showSearch
                onSearch={(value) => getRack(value)}
                filterOption={false}
              >
                {racks &&
                  racks.map((rack) => {
                    return (
                      <Option value={rack._id} key={rack.name}>
                        {rack.name}
                      </Option>
                    );
                  })}
              </Select>
            </Space>
          </Row>

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={6}>
              <div>
                <Button
                  onClick={showModal}
                  type="primary"
                  style={{
                    backgroundColor: "#069964",
                    borderColor: "#069964",
                    marginLeft: 10,
                    marginTop: 20,
                  }}
                >
                  <ImportOutlined />
                  Import
                </Button>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div>
                <Button
                  onClick={downloadTemplate}
                  type="primary"
                  style={{
                    backgroundColor: "#069964",
                    borderColor: "#069964",
                    marginLeft: -120,
                    marginTop: 20,
                  }}
                >
                  <ExportOutlined />
                  Export
                </Button>
              </div>
            </Col>
          </Row>
        </Col>

        <Row className="mt-40">
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={stockopname.data}
              pagination={{
                total,
                showSizeChanger: true,
                showTotal: showTotal,
              }}
              loading={loading}
              rowKey={(data, index) => index}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default StockOpnameForm;
