import {
  Button,
  Col,
  Input,
  message,
  Row,
  Table,
  Form,
  Breadcrumb,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { url }  from "../../constant/url";
import moment from "moment";
import { BarcodeOutlined } from "@ant-design/icons";

function StockInForm({ history, match }) {
  const { Search } = Input;
  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      sorter: true,
    },
    {
      dataIndex: "date",
      title: " Date",
      key: "date",
      render: (date) => moment(date).format("DD MMMM YYYY"),
    },
    {
      title: "Item",
      dataIndex: "item_name",
      sorter: false,
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "are",
      sorter: false,
    },
    {
      title: "Std Stcok",
      dataIndex: "std_stock",
      key: "std_stock",
      sorter: false,
    },
    {
      title: "Maker",
      dataIndex: "vendor_name",
      key: "vendor_name",
      sorter: false,
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      sorter: false,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: false,
    },
    {
      title: "Used",
      dataIndex: "used",
      key: "used",
      sorter: false,
    },
    {
      title: "Status Part",
      dataIndex: "status_part",
      key: "status_part",
      sorter: false,
    },
  ];

  const [error, setError] = useState([]);
  const [data, setData] = useState([]);
  const [scans, setScan] = useState([]);
  const [total, setTotal] = useState([]);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [type, setType] = useState("Stock In");

  useEffect(() => {
    getData();
  }, [keyword, page, perpage, sort, type]);

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/transactions`, {
        params: {
          type: type,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setData(res.data.results);
        setTotal(res.data.totalResults);
      })
      .catch((error) => {
        if (error.response) {
          message.error(error.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPerpage(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeSearch = async (value) => {
    await axios
      .get(`${url}/kanbans/code/${value}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setScan(res.data.results);

        axios.post(
          `${url}/transactions`,
          {
            code: res.data.results[0].code,
            date: moment().format("DD MMMM YYYY"),
            item_id: res.data.results[0].item_id,
            item_name: res.data.results[0].item_name,
            area: res.data.results[0].area,
            std_stock: res.data.results[0].std_stock,
            vendor_id: res.data.results[0].vendor_id,
            vendor_name: res.data.results[0].vendor_name,
            qty: res.data.results[0].qty,
            type: res.data.results[0].type,
            used: res.data.results[0].used,
            status_part: res.data.results[0].status_part,
            ket: "stock in",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {});
    console.log(data.code, scans.area);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        match.params.id
          ? `${url}/transactions/${match.params.id}`
          : `${url}/transactions`,
        {
          scans,
          ket: "stock in",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/stock-in");
      })
      .catch((err) => {
        if (err.response) {
          setError(err.response.data.errorDetails);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  return localStorage.getItem("token") ? (
    <div>
      <ul className="breadcumb">
        <li className="active">Stock In</li>
      </ul>
      <div className="content">
        <Row justify="space-between">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/Stock-in">Stock In</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Scan Stock In</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Col span={18}>
          <Row>
            <Col span={10}>
              <Form.Item></Form.Item>
            </Col>

            <Col span={14}>
              <Form.Item>
                <Search
                  height="1000px"
                  size="middle"
                  className="input-center"
                  onSearch={(value) => handleChangeSearch(value)}
                  placeholder="Scan Kanban Code"
                  prefix={<BarcodeOutlined />}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Row className="mt-40">
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={scans}
              pagination={{ total, showSizeChanger: false }}
              loading={loading}
              rowKey={(data) => data._id}
              onChange={handleTableChange}
            />
          </Col>
          <Button
            type="primary"
            onClick={handleSave}
            loading={loading}
            style={{ backgroundColor: "#069964", borderColor: "#069964" }}
          >
            Submit
          </Button>
        </Row>
      </div>
    </div>
  ) : (
    <Redirect to="/login" />
  );
}

export default StockInForm;
