import { useState } from "react";
import "./SidebarCollapsed.css";
import { Transition } from "react-transition-group";
import { Link } from "react-router-dom";

const childrenTransitionStyle = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

function SidebarCollapsed({ style, active, menus }) {
  const [childrenOpen, setChildrenOpen] = useState([]);

  const handleOpenChildren = (name) => {
    const newChildren = [...childrenOpen];
    const findChildren = newChildren.find((child) => child.name === name);
    if (findChildren) {
      findChildren.open = !findChildren.open;
      setChildrenOpen(newChildren);
    } else {
      setChildrenOpen([
        ...childrenOpen,
        {
          name,
          open: false,
        },
      ]);
    }
  };

  const activeUrl = `/${window.location.pathname.split("/")[1]}`;
  const defaultActive = localStorage.getItem("active");
  const isActive = active ? active : defaultActive;
  const selectedMenu = menus
    ? menus.find((menu) => menu.name === isActive)
    : "";

  return (
    <div className="kelola-sidebar-collapsed-content" style={style}>
      <span className="kelola-menu-title">
        {selectedMenu && selectedMenu.name}
      </span>
      <ul>
        {selectedMenu &&
          selectedMenu.children.map((child) => {
            return child.children.length > 0 ? (
              <li
                key={child.name}
                className={`kelola-menu-has-children ${
                  childrenOpen.length > 0
                    ? childrenOpen.find(
                        (children) => children.name === child.name
                      ).open
                      ? "kelola-menu-has-children-active"
                      : ""
                    : "kelola-menu-has-children-active"
                }`}
                onClick={() => handleOpenChildren(child.name)}
              >
                <span>{child.name}</span>
                <Transition
                  key={child.url}
                  in={
                    childrenOpen.length > 0
                      ? childrenOpen.find(
                          (children) => children.name === child.name
                        ).open
                      : true
                  }
                  timeout={100}
                  appear
                  unmountOnExit
                >
                  {(state) => (
                    <ul
                      className="kelola-menu-children"
                      style={childrenTransitionStyle[state]}
                    >
                      {child.children.map((grandchild) => {
                        return (
                          <li key={grandchild.name}>
                            <Link to={`/${grandchild.url}`}>
                              {grandchild.title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </Transition>
              </li>
            ) : (
              <li
                key={child.url}
                className={`kelola-menu-hasnt-children ${
                  child.url === activeUrl ? "active" : ""
                } `}
              >
                <Link to={`/${child.url}`}>{child.name}</Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default SidebarCollapsed;
