import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Table,
  Form,
} from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import { url }  from "../../constant/url";
import moment from "moment";
import audio_ok from "../../assets/ok.mp3";
import audio_ng from "../../assets/ng.mp3";

import {
  DeleteOutlined,
  BarcodeOutlined,
  CloseOutlined,
} from "@ant-design/icons";

function StockIn(history) {
  const { Search } = Input;
  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      sorter: true,
    },
    {
      dataIndex: "date",
      title: " Date Time",
      key: "date",
      render: (date) => moment(date).format("DD MMMM YYYY"),
    },
    {
      dataIndex: "created_at",
      title: "Time",
      key: "created_at",
      render: (created_at) =>  moment(created_at).format("HH:mm:ss"),
    },
    {
      title: "Item",
      dataIndex: "item_name",
      sorter: false,
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "are",
      sorter: false,
    },
    {
      title: "Std Stcok",
      dataIndex: "std_stock",
      key: "std_stock",
      sorter: false,
    },
    {
      title: "Maker",
      dataIndex: "vendor_name",
      key: "vendor_name",
      sorter: false,
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: false,
    },
    {
      title: "Used",
      dataIndex: "used",
      key: "used",
      sorter: false,
    },
    {
      title: "Status Part",
      dataIndex: "status_part",
      key: "status_part",
      sorter: false,
    },
    {
      title: "Warehouse",
      dataIndex: "warehouse_name",
      sorter: false,
    },
    {
      title: "Lemari",
      dataIndex: "lemari_name",
      sorter: false,
    },
    {
      title: "Rack",
      dataIndex: "rack_name",
      sorter: false,
    },
    {
      title: "User",
      dataIndex: "created_by",
      sorter: false,
    },

    {
      title: "",
      dataIndex: "_id",
      key: "id",
      sorter: false,
      fixed: "right",
      width: 50,
      render: (id) => {
        return (
          <>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <span onClick={() => handleDelete(id)}>
                      <DeleteOutlined />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <i className="mdi mdi-dots-vertical" />
              </span>
            </Dropdown>
          </>
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [scanItem, setScanItem] = useState([]);
  const [scansWarehouse, setScanWarehouse] = useState([]);
  const [total, setTotal] = useState([]);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "desc",
  });
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [type, setType] = useState("stock in");
  const kanbanRef = useRef(null);

  const [scan, setScan] = useState({
    warehouse: "",
    item: "",
  });

  let tone_ok = new Audio(audio_ok)
  let tone_ng = new Audio(audio_ng)

  const playAudioOk = () => {
    tone_ok.play()
  }

  const playAudioNg = () => {
    tone_ng.play()
  }

  const [isDisabled, setIsDisabled] = useState({
    warehouse: false,
    item: true,
  });

  useEffect(() => {
    getData();
    document.getElementById("warehouses_text").focus();
  }, [keyword, page, perpage, sort, type]);

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/transaction`, {
        params: {
          ket: type,
          limit: perpage,
          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure ?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    axios
      .delete(`${url}/transaction/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPerpage(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeSearchWarehouse = async (value) => {
    if(value) {
      await axios
        .get(`${url}/mapping-warehouse/code/${value}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          // if(res.data.data.type === "error") {

          // }
          playAudioOk();

          setScanWarehouse(res.data.data);
          setIsDisabled({
            warehouse: true,
            item: false,
          });
          kanbanRef.current.focus();
        })
        .catch((err) => {
          console.log('masuk error')
          playAudioNg();
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status === 401) {
              localStorage.removeItem("token");
            }
          }
        })
        .finally(() => {});
    } else {
      message.error('Please input qr-code can not be empty...!!!');
    }
  };

  const handleChangeSearchItem = async (value) => {
    setLoading(true);

    if(value){
      await axios
        .get(`${url}/item/code/${value}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setScanItem(res.data.data);
          kanbanRef.current.focus();
          axios
            .post(
              `${url}/transaction`,
              {
                code: res.data.data.code,
                date: moment().format("DD MMMM YYYY"),
                item_id: res.data.data._id,
                item_name: res.data.data.name,
                area: res.data.data.area,
                rank: res.data.data.rank,
                std_stock: res.data.data.std_stock,
                vendor_id: res.data.data.vendor_id,
                vendor_name: res.data.data.vendor_name,
                quantity: res.data.data.quantity,
                stock_min: res.data.data.stock_min,
                stock_max: res.data.data.stock_max,
                quantity_update: res.data.data.quantity_update,
                type: res.data.data.type,
                used: res.data.data.used,
                status_part: res.data.data.status_part,
                warehouse_id: scansWarehouse.warehouse_id,
                warehouse_code: scansWarehouse.warehouse_code,
                warehouse_name: scansWarehouse.warehouse_name,
                rack_id: scansWarehouse.rack_id,
                rack_name: scansWarehouse.rack_name,
                lemari_id: scansWarehouse.lemari_id,
                lemari_code: scansWarehouse.lemari_code,
                lemari_name: scansWarehouse.lemari_name,
                ket: "stock in",
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then((res) => {
              playAudioOk()
              message.success(res.data.message);
              getData();
              setScan({
                ...scan,
                item: "",
              });
            })
            .catch((err) => {
              playAudioNg()
              
              if (err.response) {
                message.error(err.response.data.message);
              }
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch((err) => {
          playAudioNg()
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status === 401) {
              localStorage.removeItem("token");
            }
          }
        })
        .finally(() => {
          getData();
        });
    } else {
      message.error('Please input qr-code can not be empty...!!!');
    }
  };

  const handleReset = () => {
    setScan({
      ...scan,
      warehouse: "",
    });
    setIsDisabled({
      warehouse: false,
      item: true,
    });
    document.getElementById("warehouses_text").focus();
  };

  return localStorage.getItem("token") ? (
    <div>
      <ul className="breadcumb">
        <li className="active">Stock In</li>
      </ul>
      <div className="content">
        <Row justify="center" align="top">
          <Col span={12}>
            <Form.Item>
              <Search
                id="warehouses_text"
                disabled={isDisabled.warehouse}
                allowClear="true"
                height="600px"
                size="middle"
                value={scan.warehouse}
                onChange={(e) =>
                  setScan({ ...scan, warehouse: e.target.value })
                }
                autoFocus
                onSearch={(value) => handleChangeSearchWarehouse(value)}
                placeholder="Scan Warehouse ID"
                prefix={<BarcodeOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Button
              htmlType="reset"
              type="primary"
              style={{
                backgroundColor: "red",
                borderColor: "red",
                marginLeft: 10,
              }}
              onClick={handleReset}
            >
              <CloseOutlined />
            </Button>
          </Col>
        </Row>
        <Row justify="center" align="top">
          <Col span={12}>
            <Form.Item>
              <Search
                disabled={isDisabled.item}
                ref={kanbanRef}
                value={scan.item}
                onChange={(e) => setScan({ ...scan, item: e.target.value })}
                allowClear="true"
                height="600px"
                size="middle"
                onSearch={(value) => handleChangeSearchItem(value)}
                placeholder="Scan Item Code"
                prefix={<BarcodeOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={4}></Col>
        </Row>

        <Row className="mt-40">
          <Col xs={24}>
            <Table
              columns={columns}
              dataSource={data}
              pagination={{ total, showSizeChanger: true }}
              loading={loading}
              rowKey={(data) => data._id}
              onChange={handleTableChange}
              style={{ maxWidth: 1200 }}
              scroll={{ x: 2500 }}
            />
          </Col>
        </Row>
      </div>
    </div>
  ) : (
    <Redirect to="/login" />
  );
}

export default StockIn;
