import { Button, Col, Input, message, Row, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./SettingForm.css";
import axios from "axios";
import { url }  from "../../constant/url";
import { values } from "underscore";

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function SettingForm({ history, match }) {
  const [data, setData] = useState({
    variable: "",
    values: [],
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (match.params.id) {
      getData();
    }
  }, []);

  const handleChange = (name, value) => {
    setErrors(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleAddValue = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setData({
        ...data,
        values: [
          ...data.values,
          {
            id: Math.random(),
            name: data.value,
          },
        ],
        value: "",
      });
    }
  };

  const handleDeleteValue = (id) => {
    const { values } = data;
    const filterValues = values.filter((value) => value.id !== id);

    setData({
      ...data,
      values: filterValues,
    });
  };
  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/settings/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setData({
          ...data,
          variable: res.data.data.variable,
          values: res.data.data.value,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      variable: "",
      value: [],
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(
        match.params.id
          ? `${url}/settings/${match.params.id}`
          : `${url}/settings`,
        {
          variable: data.variable,
          value: data.values,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/setting");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/setting">Setting</Link>
        </li>
        <li className="active">
          {match.params.id ? "Edit Setting" : "Add Setting"}
        </li>
      </ul>
      <div className="content">
        <form onSubmit={handleSave}>
          <Row gutter={20}>
            <Col md={18} xs={24}>
              <div className="form-group">
                <label className="form-label">
                  Variable <span className="error-text">*</span>
                </label>
                <Input
                  value={data.variable}
                  className={errors && errors.variable ? "is-error" : ""}
                  onChange={(e) => handleChange("variable", e.target.value)}
                />
                {errors && errors.variable && (
                  <span className="error-text">{errors.variable[0]}</span>
                )}
              </div>
              <div className="form-group">
                <label className="form-label">
                  Value <span className="error-text">*</span>
                </label>
                <Input
                  value={data.value}
                  onKeyPress={handleAddValue}
                  onChange={(e) => handleChange("value", e.target.value)}
                  placeholder="Press enter to add"
                />
                {errors && errors.value && (
                  <span className="error-text">{errors.value[0]}</span>
                )}
              </div>
              <div className="form-group" {...tailLayout}>
                {data.values &&
                  data.values.map((value) => {
                    return (
                      <Tag
                        key={value.id}
                        closable
                        onClose={() => handleDeleteValue(value.id)}
                      >
                        {value.name}
                      </Tag>
                    );
                  })}
              </div>

              <div className="form-group">
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{ backgroundColor: "#069964", borderColor: "#069964" }}
                >
                  Submit
                </Button>

                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  loading={loading}
                  style={{
                    backgroundColor: "#069964",
                    marginLeft: 10,
                    borderColor: "#069964",
                  }}
                >
                  Reset
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default SettingForm;
