import { Button, Col, Input, message, Row, Select, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./WarehouseForm.css";
import axios from "axios";
import { url }  from "../../constant/url";
import { CloseOutlined } from "@ant-design/icons";

function WarehouseForm({ history, match }) {
  const [data, setData] = useState({
    code: "",
    name: "",
    rack_id: "",
    rack_name: "",
    lemari_id: "",
    lemari_name: "",
  });

  const [dataLemari, setDataLemari] = useState({
    id: "",
    code: "",
    name: "",
  });

  const [dataWarehouse, setDataWarehouse] = useState({
    id: "",
    code: "",
    name: "",
  });

  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [lemaris, setLemaris] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [deleteRacks, setdeleteRacks] = useState(null);
  const [deleteLemaris, setDeleteLemaris] = useState(null);
  const [deleteWarehouses, setdeleteWarehouses] = useState(null);
  const [racks, setRacks] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalRack, setModalRack] = useState(false);
  const [modalLemari, setModalLemari] = useState(false);

  const { Option } = Select;

  useEffect(() => {
    if (match.params.id) {
      getData();
    }
  }, []);

  const handleChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeLemari = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });

    setDataLemari({
      ...dataLemari,
      [name]: value,
    });
  };

  const handleChangeWarehouse = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });

    setDataWarehouse({
      ...dataWarehouse,
      [name]: value,
    });
  };

  const handleOkLemari = () => {
    handleSaveLemari();
    setLemaris([
      ...lemaris,
      {
        id: data.modal,
        name: data.modal,
      },
    ]);
    setModalLemari(false);
  };

  const handleChangeSelectLemari = (name, value) => {
    if (value) {
      if (value.value === "new") {
        setModalLemari(true);
      } else {
        setData({
          ...data,
          [`${name}_id`]: value.value,
          [`${name}_name`]: value.label,
          [`${name}_code`]: value.label,
        });
        setDeleteLemaris(value.value);
      }
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const handleCancelLemari = () => {
    setModalLemari(false);
  };

  const handleChangeSelectWarehouse = (name, value) => {
    if (value) {
      if (value.value === "new") {
        setModal(true);
      } else {
        setData({
          ...data,
          [`${name}_id`]: value.value,
          [`${name}_name`]: value.label,
          [`${name}_code`]: value.label,
        });
        setdeleteWarehouses(value.value);
      }
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const handleSaveLemari = () => {
    setLoading(true);

    axios
      .post(
        `${url}/lemari`,
        {
          name: dataLemari.name,
          code: dataLemari.code,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLemaris([
          ...lemaris,
          {
            ...res.data.data,
          },
        ]);
        setData({
          ...data,
          lemari_id: res.data.data._id,
          lemari_name: res.data.data.name,
        });
        setDataLemari({
          name: "",
          code: "",
          id: "",
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOkWarehouse = () => {
    handleSaveWarehouse();
    setModal(false);
  };

  const handleSaveWarehouse = () => {
    setLoading(true);

    axios
      .post(
        `${url}/warehouse`,
        {
          name: dataWarehouse.name,
          code: dataWarehouse.code,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setWarehouses([
          ...lemaris,
          {
            ...res.data.data,
          },
        ]);
        setData({
          ...data,
          warehouse_id: res.data.data._id,
          warehouse_name: res.data.data.name,
        });
        setDataWarehouse({
          name: "",
          code: "",
          id: "",
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setModal(false);
  };

  const handleChangeSelectRack = (name, value) => {
    if (value) {
      if (value.value === "new") {
        setModalRack(true);
      } else {
        setData({
          ...data,
          [`${name}_id`]: value.value,
          [`${name}_name`]: value.label,
        });
        setdeleteRacks(value.value);
      }
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const handleOkRack = () => {
    handleSaveRack();
    setRacks([
      ...racks,
      {
        id: data.modal,
        name: data.modal,
      },
    ]);
    setModalRack(false);
  };

  const handleSaveRack = () => {
    setLoading(true);

    axios
      .post(
        `${url}/rack`,
        {
          name: data.modal,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setData({
          ...data,
          rack_id: res.data.rack.id,
          rack_name: res.data.rack.name,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancelRack = () => {
    setModalRack(false);
  };

  const handleDeleteWarehouse = () => {
    if (deleteWarehouses) {
      Modal.confirm({
        title: "Are you sure ?",
        content: (
          <div>
            <p>Deleted data cannot be restored</p>
          </div>
        ),
        okText: "Delete",
        showCancel: true,
        onCancel: () => {
          return;
        },
        cancelText: "Cancel",
        confirmLoading: loading,
        onOk: async () => {
          confirmDeleteWarehouse();
        },
      });
    } else {
      message.error("Please choose options first !");
    }
  };

  const confirmDeleteWarehouse = () => {
    setLoading(true);
    axios
      .delete(`${url}/warehouse/${deleteWarehouses}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        setData({
          ...data,
          warehouse_id: "",
          warehouse_name: "",
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    console.log(deleteWarehouses);
  };

  const handleDeleteRack = () => {
    if (deleteRacks) {
      Modal.confirm({
        title: "Are you sure ?",
        content: (
          <div>
            <p>Deleted data cannot be restored</p>
          </div>
        ),
        okText: "Delete",
        showCancel: true,
        onCancel: () => {
          return;
        },
        cancelText: "Cancel",
        confirmLoading: loading,
        onOk: async () => {
          confirmDeleteRack();
        },
      });
    } else {
      message.error("Please choose options first!");
    }
  };

  const confirmDeleteRack = () => {
    setLoading(true);
    axios
      .delete(`${url}/rack/${deleteRacks}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        setData({
          ...data,
          rack_id: "",
          rack_name: "",
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    console.log(deleteRacks);
  };

  const handleDeleteLemari = () => {
    if (deleteLemaris) {
      Modal.confirm({
        title: "Are you sure ?",
        content: (
          <div>
            <p>Deleted data cannot be restored</p>
          </div>
        ),
        okText: "Delete",
        showCancel: true,
        onCancel: () => {
          return;
        },
        cancelText: "Cancel",
        confirmLoading: loading,
        onOk: async () => {
          confirmDeleteLemari();
        },
      });
    } else {
      message.error("Please choose options first !");
    }
  };

  const confirmDeleteLemari = () => {
    setLoading(true);
    axios
      .delete(`${url}/lemari/${deleteLemaris}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        setData({
          ...data,
          lemari_id: "",
          lemari_name: "",
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    console.log(deleteLemaris);
  };

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/mapping-warehouse/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const {
          warehouse_id,
          warehouse_name,
          rack_id,
          rack_name,
          lemari_id,
          lemari_name,
          permissions,
        } = res.data.data;
        setData({
          ...data,
          warehouse_id,
          warehouse_name,
          permissions,
          rack_id,
          rack_name,
          lemari_id,
          lemari_name,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLemari = async (keyword) => {
    setItemsLoading(true);
    await axios
      .get(`${url}/lemari/list`, {
        params: {
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLemaris(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setItemsLoading(false);
      });
  };

  const getWarehouse = async (keyword) => {
    setItemsLoading(true);
    await axios
      .get(`${url}/warehouse/list`, {
        params: {
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setWarehouses(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setItemsLoading(false);
      });
  };

  const getRack = async (keyword) => {
    setItemsLoading(true);
    await axios
      .get(`${url}/rack/list`, {
        params: {
          keyword,
          columns: ["name"],

          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setRacks(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setItemsLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      code: "",
      name: "",
      rack_id: "",
      rack_name: "",
      lemari_id: "",
      lemari_name: "",
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        match.params.id
          ? `${url}/mapping-warehouse/${match.params.id}`
          : `${url}/mapping-warehouse`,
        {
          warehouse_id: data.warehouse_id,
          warehouse_name: data.warehouse_name,
          rack_id: data.rack_id,
          rack_name: data.rack_name,
          lemari_id: data.lemari_id,
          lemari_name: data.lemari_name,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/warehouse");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <Modal
        title="Add Warehouse "
        visible={modal}
        onOk={handleOkWarehouse}
        onCancel={handleCancel}
        closable
      >
        <Input
          value={dataWarehouse.code}
          placeholder="Code"
          onChange={(e) => handleChangeWarehouse("code", e.target.value)}
        />
        <Input
          style={{ marginTop: 20 }}
          value={dataWarehouse.name}
          placeholder="Name"
          onChange={(e) => handleChangeWarehouse("name", e.target.value)}
        />
      </Modal>

      <Modal
        title="Add Rack"
        visible={modalRack}
        onOk={handleOkRack}
        onCancel={handleCancelRack}
        closable
      >
        <Input
          value={data.modal}
          placeholder="Add Rack "
          onChange={(e) => handleChange("modal", e.target.value)}
        />
      </Modal>

      <Modal
        title="Add Lemari "
        visible={modalLemari}
        onOk={handleOkLemari}
        onCancel={handleCancelLemari}
        closable
      >
        <Input
          value={dataLemari.code}
          placeholder="Code"
          onChange={(e) => handleChangeLemari("code", e.target.value)}
        />
        <Input
          style={{ marginTop: 20 }}
          value={dataLemari.name}
          placeholder="Name"
          onChange={(e) => handleChangeLemari("name", e.target.value)}
        />
      </Modal>

      <ul className="breadcumb">
        <li>
          <Link to="/warehouse">Warehouse</Link>
        </li>
        <li className="active">
          {match.params.id ? "Edit Warehouse" : "Add Warehouse"}
        </li>
      </ul>
      <div className="content">
        <form onSubmit={handleSave}>
          <Row gutter={20}>
            <Col md={18} xs={24}>
              <div className="form-group">
                <label className="form-label" style={{ marginRight: 500 }}>
                  Warehouse <span className="error-text">*</span>
                </label>
                <Select
                  allowClear
                  labelInValue
                  style={{ width: 500 }}
                  onChange={(value) =>
                    handleChangeSelectWarehouse("warehouse", value, data._id)
                  }
                  onFocus={() => getWarehouse()}
                  filterOption={false}
                  showSearch
                  value={{
                    key: data.warehouse_id,
                    label: data.warehouse_name,
                  }}
                >
                  {warehouses &&
                    warehouses.map((warehouse) => {
                      return (
                        <Option value={warehouse._id} key={warehouse._id}>
                          {warehouse.name}
                        </Option>
                      );
                    })}
                  <Option value="new">+ Create new Warehouse</Option>
                </Select>
                {errors && errors.name && (
                  <span className="error-text">{errors.name[0]}</span>
                )}
                {data.warehouse_name ? (
                  <Button
                    type="primary"
                    style={{
                      marginLeft: 5,
                      backgroundColor: "#069964",
                      borderColor: "#069964",
                    }}
                    onClick={() => handleDeleteWarehouse()}
                  >
                    Delete
                    <CloseOutlined />
                  </Button>
                ) : (
                  ""
                )}
              </div>
              <div className="form-group">
                <label className="form-label" style={{ marginRight: 500 }}>
                  Lemari <span className="error-text">*</span>
                </label>
                <Select
                  allowClear
                  labelInValue
                  style={{ width: 500 }}
                  onChange={(value) =>
                    handleChangeSelectLemari("lemari", value, data._id)
                  }
                  onFocus={() => getLemari()}
                  filterOption={false}
                  showSearch
                  value={{
                    key: data.lemari_id,
                    label: data.lemari_name,
                  }}
                >
                  {lemaris &&
                    lemaris.map((lemari) => {
                      return (
                        <Option value={lemari._id} key={lemari._id}>
                          {lemari.name}
                        </Option>
                      );
                    })}
                  <Option value="new">+ Create new Lemari</Option>
                </Select>
                {errors && errors.name && (
                  <span className="error-text">{errors.name[0]}</span>
                )}
                {data.lemari_name ? (
                  <Button
                    type="primary"
                    style={{
                      marginLeft: 5,
                      backgroundColor: "#069964",
                      borderColor: "#069964",
                    }}
                    onClick={() => handleDeleteLemari(deleteLemaris)}
                  >
                    Delete
                    <CloseOutlined />
                  </Button>
                ) : (
                  ""
                )}
              </div>

              <div className="form-group">
                <label className="form-label" style={{ marginRight: 500 }}>
                  Rack <span className="error-text">*</span>
                </label>
                <Select
                  allowClear
                  labelInValue
                  style={{ width: 500 }}
                  onChange={(value) =>
                    handleChangeSelectRack("rack", value, data._id)
                  }
                  onFocus={() => getRack()}
                  filterOption={false}
                  showSearch
                  value={{
                    key: data.rack_id,
                    label: data.rack_name,
                  }}
                >
                  {racks &&
                    racks.map((rack) => {
                      return (
                        <Option value={rack._id} key={rack._id}>
                          {rack.name}
                        </Option>
                      );
                    })}
                  <Option value="new">+ Create new Rack</Option>
                </Select>
                {errors && errors.name && (
                  <span className="error-text">{errors.name[0]}</span>
                )}
                {data.rack_name ? (
                  <Button
                    type="primary"
                    style={{
                      marginLeft: 5,
                      backgroundColor: "#069964",
                      borderColor: "#069964",
                    }}
                    onClick={() => handleDeleteRack()}
                  >
                    Delete
                    <CloseOutlined />
                  </Button>
                ) : (
                  ""
                )}
              </div>

              <div className="form-group">
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{ backgroundColor: "#069964", borderColor: "#069964" }}
                >
                  Submit
                </Button>

                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  style={{
                    backgroundColor: "#069964",
                    marginLeft: 10,
                    borderColor: "#069964",
                  }}
                >
                  Reset
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default WarehouseForm;
