import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import "./Dashboard.css";
import { message, Space, Select, Col, Row } from "antd";
import axios from "axios";
import { url }  from "../../constant/url";
import { Bar } from "react-chartjs-2";
import _ from "underscore";
import ChartDataLabels from "chartjs-plugin-datalabels";
import iconX from "../../assets/iconX.png";
import iconZero from "../../assets/iconO.png";

const { Option } = Select;
function Dashboard() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [itemDataBar1, setitemDataBar1] = useState([]);
  const [itemDataBarMap, setItemDataBarMap] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [lemaris, setLemaris] = useState([]);
  const [quantityRankS, setQuantityRankS] = useState([]);
  const [quantityRankA, setQuantityRankA] = useState([]);
  const [quantityRankB, setQuantityRankB] = useState([]);
  const [racks, setRacks] = useState([]);
  const [error, setError] = useState(null);

  const [filter, setFilter] = useState({
    warehouse_id: "",
    warehouse_name: "",
    rack_id: "",
    rack_name: "",
    lemari_id: "",
    lemari_name: "",
  });

  const getDataBar = () => {
    setLoading(true);

    let itemQuantity = [];
    let itemName = [];
    axios
      .get(`${url}/dashboard/bar1`, {
        params: {
          keyword,
          warehouse_id: filter.warehouse_id,
          lemari_id: filter.lemari_id,
          rack_id: filter.rack_id,
          columns: ["item_id", "item_name", "qty", "stock_min", "stock_max"],

          sort: "rank",
          order: "ascend",
        },

        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setData(res.data.data);
        setQuantityRankS(res.data.data[0].quantity);
        setQuantityRankA(res.data.data[1].quantity);
        setQuantityRankB(res.data.data[2].quantity);

        console.log(res.data.data);

        for (const dataObj of res.data.data) {
          !filter.warehouse_id && !filter.lemari_id && !filter.rack_id
            ? itemQuantity.push(dataObj.quantity)
            : itemQuantity.push(dataObj.quantity);
          !filter.warehouse_id && !filter.lemari_id && !filter.rack_id
            ? itemName.push(dataObj.name)
            : itemName.push(dataObj.name);
        }

        setitemDataBar1({
          labels: itemName,
          datasets: [
            {
              label: "Total Parts",
              data: itemQuantity,
              // datas: res.data.data,
              fill: false,
              backgroundColor: "#17A8F5",
              borderColor: "#17A8F5 ",
            },
          ],
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
          }
          message.error(error.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDataBarMap = () => {
    setLoading(true);
    axios
      .get(`${url}/dashboard/bar-map`, {
        params: {
          keyword,
          warehouse_id: filter.warehouse_id,
          lemari_id: filter.lemari_id,
          rack_id: filter.rack_id,
          columns: ["item_id", "item_name", "qty", "stock_min", "stock_max"],
          sort: "rank",
          order: "ascend",
        },

        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setItemDataBarMap(res.data.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
          }
          message.error(error.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDataBar();
    getDataBarMap();
  }, [filter]);

  var number = [quantityRankS, quantityRankA, quantityRankB];
  var maximal = Math.max.apply(Math, number);

  const options = {
    plugins: {
      datalabels: {
        font: {
          size: "30",
        },
        labels: {
          title: {
            color: "Black",
            align: "top",
            anchor: "end",
          },
        },
      }
    },
    // tooltips: {
    //   callbacks: {
    //       label: function(tooltipItem) {
    //         console.log(tooltipItem)
    //           return tooltipItem;
    //       }
    //   }
    // },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            precision: 0,
            max: maximal + 1,
          },
          scaleLabel: {
            display: true,
            labelString: "TOTAL PART",
            fontSize: 25,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontSize: 30,
          },
          title: {},
        },
      ],
    },
  };

  // const optionsMap =

  const handleChangeViewWarehouse = (name, value) => {
    setError(null);

    if (value) {
      setFilter({
        ...filter,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setFilter({
        ...filter,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const handleChangeViewRack = (name, value) => {
    setError(null);

    if (value) {
      setFilter({
        ...filter,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setFilter({
        ...filter,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const handleChangeViewLemari = (name, value) => {
    setError(null);

    if (value) {
      setFilter({
        ...filter,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setFilter({
        ...filter,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const getWarehouse = async (keyword, type) => {
    await axios
      .get(`${url}/warehouse/list`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setWarehouses(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {});
  };

  const getRack = async (keyword, type) => {
    await axios
      .get(`${url}/rack/list`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setRacks(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {});
  };
  const getLemari = async (keyword, type) => {
    await axios
      .get(`${url}/lemari/list`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLemaris(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {});
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      width: 265,
    },
    {
      title: "Cash Assets",
      className: "column-money",
      dataIndex: "money",
      width: 475,
      align: "center",
    },
    {
      title: "Address",
      align: "center",
      dataIndex: "address",
    },
  ];

  return localStorage.getItem("token") ? (
    <div>
      <ul className="breadcumb">
        <li className="active">Dashboard</li>
      </ul>
      <div className="content">
        <h1>Welcome</h1>
        <Col className="align-right">
          <Row type="flex" gutter={[12, 24]} justify="space-around">
            <Col></Col>

            <Space direction="vertical" size={12}>
              <Select
                allowClear="true"
                placeholder=" Warehouse"
                style={{ width: 265 }}
                labelInValue
                onChange={(value) =>
                  handleChangeViewWarehouse("warehouse", value)
                }
                onFocus={() => getWarehouse("")}
                showSearch
                onSearch={(value) => getWarehouse(value)}
                filterOption={false}
              >
                {warehouses &&
                  warehouses.map((warehouse) => {
                    return (
                      <Option value={warehouse._id} key={warehouse.name}>
                        {warehouse.name}
                      </Option>
                    );
                  })}
              </Select>
            </Space>
            <Space direction="vertical" size={12}>
              <Select
                allowClear="true"
                placeholder=" Lemari"
                style={{ width: 265 }}
                labelInValue
                onChange={(value) => handleChangeViewLemari("lemari", value)}
                onFocus={() => getLemari("")}
                showSearch
                onSearch={(value) => getLemari(value)}
                filterOption={false}
              >
                {lemaris &&
                  lemaris.map((lemari) => {
                    return (
                      <Option value={lemari._id} key={lemari.name}>
                        {lemari.name}
                      </Option>
                    );
                  })}
              </Select>
            </Space>
            <Space direction="vertical" size={12}>
              <Select
                allowClear="true"
                placeholder=" Rack"
                style={{ width: 265 }}
                labelInValue
                onChange={(value) => handleChangeViewRack("rack", value)}
                onFocus={() => getRack("")}
                showSearch
                onSearch={(value) => getRack(value)}
                filterOption={false}
              >
                {racks &&
                  racks.map((rack) => {
                    return (
                      <Option value={rack._id} key={rack.name}>
                        {rack.name}
                      </Option>
                    );
                  })}
              </Select>
            </Space>
          </Row>
        </Col>

        <div style={{ textAlign: "center", marginTop: 50, fontSize: 30 }}>
          RANK PART STOCK {"<"} MIN STOCK
        </div>

        <table border="1" cellpadding="10" width={350}>
          <tr>
            <div style={{ marginLeft: "1000" }}>
              <img style={{ width: 50, height: 50 }} src={iconZero} /> : TIDAK
              ADA DATA YANG {"<"} STOCK MIN
            </div>
            <div>
              <img
                style={{ width: 50, height: 50, marginLeft: "auto" }}
                src={iconX}
              />{" "}
              : ADA DATA YANG {"<"} STOCK MIN
            </div>
          </tr>
        </table>

        <div style={{ width: "1150px", marginTop: 35, fontSize: 15 }}>
          <Bar data={itemDataBar1} options={options} />
          <table id="customers">
            <tr>
              <td width="100">
                <img
                  style={{ width: 80, height: 80, marginLeft: 100 }}
                  src={quantityRankS > 0 ? iconX : iconZero}
                />
              </td>
              <td width="475">
                {" "}
                <img
                  style={{ width: 80, height: 80, marginLeft: 160 }}
                  src={quantityRankA > 0 ? iconX : iconZero}
                />
              </td>
              <td width="100">
                <img
                  style={{ width: 80, height: 80, marginLeft: 160 }}
                  src={quantityRankB > 0 ? iconX : iconZero}
                />
              </td>
            </tr>
          </table>
        </div>

        {itemDataBarMap &&
          itemDataBarMap.map((x) => {
            return (
              <div
                style={{
                  textAlign: "center",
                  width: "1150px",
                  marginTop: 55,
                  fontSize: 30,
                }}
              >
                {"RANK " + x.rank}
                <Bar
                  data={{
                    labels: _.pluck(x.item, "name"),
                    datasets: [
                      {
                        label: "Stock Min",
                        data: _.pluck(x.item, "stock_min"),
                        type: "line",
                        fill: false,
                        backgroundColor: "#FFFF00",
                        borderColor: "#FFFF00",
                      },
                      {
                        label: "Stock Max",
                        data: _.pluck(x.item, "stock_max"),
                        fill: false,
                        type: "line",
                        backgroundColor: "#FF0000",
                        borderColor: "#FF0000",
                      },

                      {
                        label: "Quantity",
                        data: _.pluck(x.item, "quantity"),
                        fill: false,
                        backgroundColor: "#17A8F5",
                        borderColor: "#000000",
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      datalabels: {
                        font: {
                          size: "15",
                        },
                        labels: {
                          title: {
                            color: "Black",
                            align: "top",
                            anchor: "end",
                          },
                        },
                      },
                    },
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            precision: 0,
                            max:
                              Math.max.apply(
                                Math,
                                _.pluck(x.item, "quantity")
                              ) == "-Infinity"
                                ? 3
                                : Math.max.apply(
                                    Math,
                                    _.pluck(x.item, "quantity")
                                  ) + 5,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: "TOTAL QUANTITY",
                            fontSize: 25,
                          },
                        },
                      ],
                      xAxes: [
                        {
                          ticks: {
                            fontSize: 15,
                          },
                          title: {},
                        },
                      ],
                    },
                  }}
                />
              </div>
            );
          })}
      </div>
    </div>
  ) : (
    <Redirect to="/login" />
  );
}

export default Dashboard;
