import {
  Button,
  Col,
  Input,
  message,
  Row,
  Table,
  Popconfirm,
  Form,
  Select,
  Modal,
  Avatar,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ItemForm.css";
import axios from "axios";
import { url }  from "../../constant/url";
import profile from "../../assets/item.png";
import { CloseOutlined } from "@ant-design/icons";

function ItemForm({ history, match }) {
  const [data, setData] = useState({
    name: "",
    number: "",
    code: "",
    area: "",
    rank: "",
    std_stock: "",
    vendor_id: "",
    vendor_name: "",
    category_id: "",
    category_name: "",
    unit_id: "",
    unit_name: "",
    price: "",
    stock_min: "",
    stock_max: "",
    quantity: 0,
    images: "",
    images_url: "",
    qty_update: "",
    type: "",
    used: "",
    status_part: "",
  });

  const [page] = useState(1);
  const [sort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [itemunits, setItemunits] = useState([]);
  const [units, setUnits] = useState([]);
  const [categories, setCategories] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalUnit, setModalUnit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [ranks, setRanks] = useState([]);
  const [deleteCategories, setDeleteCateroies] = useState(null);
  const [deleteUnits, setDeleteUnits] = useState(null);

  const { Option } = Select;

  useEffect(() => {
    if (match.params.id) {
      getData();
    }
  }, []);

  const handleChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });

    setData({
      ...data,
      [name]: value,
    });
  };

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/item/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const {
          number,
          code,
          area,
          std_stock,
          rank,
          vendor_id,
          vendor_name,
          name,
          category_id,
          category_name,
          unit_id,
          unit_name,
          price,
          stock_min,
          stock_max,
          quantity,
          type,
          used,
          status_part,
        } = res.data.data;
        setData({
          ...data,
          number,
          code,
          area,
          rank,
          std_stock,
          vendor_id,
          vendor_name,
          name,
          category_id,
          category_name,
          unit_id,
          unit_name,
          price,
          stock_min,
          stock_max,
          quantity,
          type,
          used,
          status_part,
        });
        setItemunits(res.data.data.units);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCategory = async (keyword) => {
    setItemsLoading(true);

    await axios
      .get(`${url}/category/list`, {
        params: {
          keyword,
          columns: ["category_id", "category_name"],
          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setItemsLoading(false);
      });
  };

  console.log(data.rank, "rank");

  const getVendor = async (keyword, type) => {
    setItemsLoading(true);

    await axios
      .get(`${url}/vendor/list`, {
        params: {
          keyword,
          columns: ["vendor_id", "vendor_name"],

          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setVendors(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setItemsLoading(false);
      });
  };

  const getUnit = async (keyword) => {
    setItemsLoading(true);

    await axios
      .get(`${url}/unit/list`, {
        params: {
          keyword,
          columns: ["unit_id", "unit_name"],
          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setUnits(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setItemsLoading(false);
      });
  };

  const getRank = async () => {
    await axios
      .get(`${url}/settings/find`, {
        params: {
          variable: "RANK",
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setRanks(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleBeforeUpload = (file) => {
    setData({
      ...data,
      images_url: "",
      images: file,
    });
    return false;
  };

  const handleRemove = () => {
    setData({
      ...data,
      images: "",
      images_url: "",
    });
  };

  const handleChangeSelect = (name, value) => {
    setErrors(null);
    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
        [`${name}`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
        [`${name}`]: null,
      });
    }
    console.log(value, "inii");
  };

  const handleReset = () => {
    setData({
      number: "",
      name: "",
      code: "",
      area: "",
      std_stock: "",
      rank: "",
      vendor_id: "",
      vendor_name: "",
      category_id: "",
      category_name: "",
      unit_id: "",
      unit_name: "",
      price: "",
      stock_min: "",
      stock_max: "",
      conversion: "",
      quantity: "",
      itemunits: "",
      type: "",
      used: "",
      status_part: "",
    });
  };

  const handleAddRow = () => {
    setItemunits([
      ...itemunits,
      {
        id: Math.random(),
        unit_id: "",
        unit_name: "",
        conversion: "",
      },
    ]);
  };

  const handleDelete = (id) => {
    const details = itemunits.filter((itemunits) => itemunits._id !== id);
    setItemunits(details);
  };

  const handleChangeSelectCategory = (name, value) => {
    setErrors(null);
    if (value) {
      if (value.value === "new") {
        setModal(true);
      } else {
        setData({
          ...data,
          [`${name}_id`]: value.value,
          [`${name}_name`]: value.label,
        });
        setDeleteCateroies(value.value);
      }
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
    console.log(value, "tes");
  };

  const handleChangeSelectUnit = (name, value) => {
    setErrors(null);
    if (value) {
      if (value.value === "new") {
        setModalUnit(true);
      } else {
        setData({
          ...data,
          [`${name}_id`]: value.value,
          [`${name}_name`]: value.label,
        });
        setDeleteUnits(value.value);
      }
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const handleOk = () => {
    handleSaveCategory();
    setCategories([
      ...categories,
      {
        id: data.modal,
        name: data.modal,
      },
    ]);
    setModal(false);
  };

  const handleSaveCategory = () => {
    setLoading(true);

    axios
      .post(
        `${url}/category`,
        {
          name: data.modal,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setData({
          ...data,
          category_id: res.data.category.id,
          category_name: res.data.category.name,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOkUnit = () => {
    handleSaveUnit();
    setUnits([
      ...units,
      {
        id: data.modal,
        name: data.modal,
      },
    ]);
    setData({
      ...data,
      unit: data.modal,
      modal: "",
    });

    setModalUnit(false);
  };

  const handleSaveUnit = () => {
    setLoading(true);

    axios
      .post(
        `${url}/unit`,
        {
          name: data.modal,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setData({
          ...data,
          unit_id: res.data.unit.id,
          unit_name: res.data.unit.name,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setModal(false);
  };

  const handleCancelUnit = () => {
    setModalUnit(false);
  };

  const handleChangeSelectRow = (name, value, id) => {
    const index = itemunits.findIndex((x) => x.id === id);
    const newItemunits = [...itemunits];
    newItemunits[index][`${name}_id`] = value.value;
    newItemunits[index][`${name}_name`] = value.label;
    setItemunits(newItemunits);
  };

  const handleChangeRow = (name, value, id) => {
    const index = itemunits.findIndex((x) => x.id === id);
    const newItemunits = [...itemunits];
    newItemunits[index][name] = value;
    setItemunits(newItemunits);
  };

  const handleDeleteCategories = () => {
    if (deleteCategories) {
      Modal.confirm({
        title: "Are you sure ?",
        content: (
          <div>
            <p>Deleted data cannot be restored</p>
          </div>
        ),
        okText: "Delete",
        showCancel: true,
        onCancel: () => {
          return;
        },
        cancelText: "Cancel",
        confirmLoading: loading,
        onOk: async () => {
          confirmDeleteCategories();
        },
      });
    } else {
      message.error("Please choose options first !");
    }
  };

  const confirmDeleteCategories = () => {
    setLoading(true);
    axios
      .delete(`${url}/category/${deleteCategories}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        setData({
          ...data,
          category_id: "",
          category_name: "",
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    console.log(deleteCategories);
  };

  const handleDeleteUnits = () => {
    if (deleteUnits) {
      Modal.confirm({
        title: "Are you sure ?",
        content: (
          <div>
            <p>Deleted data cannot be restored</p>
          </div>
        ),
        okText: "Delete",
        showCancel: true,
        onCancel: () => {
          return;
        },
        cancelText: "Cancel",
        confirmLoading: loading,
        onOk: async () => {
          confirmdeleteUnits();
        },
      });
    } else {
      message.error("Please choose options first !");
    }
  };

  const confirmdeleteUnits = () => {
    setLoading(true);
    axios
      .delete(`${url}/unit/${deleteUnits}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        setData({
          ...data,
          unit_id: "",
          unit_name: "",
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });

    console.log(deleteUnits);
  };

  const handleSave = (e) => {
    e.preventDefault();
    let fd = new FormData();
    fd.set("name", data.name);
    fd.set("code", data.code);
    fd.set("area", data.area);
    fd.set("std_stock", data.std_stock);
    fd.set("rank", data.rank);
    fd.set("vendor_id", data.vendor_id);
    fd.set("vendor_name", data.vendor_name);
    fd.set("number", data.number);
    fd.set("category_id", data.category_id);
    fd.set("category_name", data.category_name);
    fd.set("unit_id", data.unit_id);
    fd.set("unit_name", data.unit_name);
    fd.set("price", data.price);
    fd.set("stock_min", data.stock_min);
    fd.set("stock_max", data.stock_max);
    fd.set("type", data.type);
    fd.set("used", data.used);
    fd.set("quantity", data.quantity);
    fd.set("status_part", data.status_part);
    fd.append("image", data.images);
    fd.set("itemunits", JSON.stringify(itemunits));
    setLoading(true);

    axios
      .post(
        match.params.id ? `${url}/item/${match.params.id}` : `${url}/item`,
        fd,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/item");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <Modal
        title="Add Category "
        visible={modal}
        onOk={handleOk}
        onCancel={handleCancel}
        closable
      >
        <Input
          value={data.modal}
          placeholder="Add Category"
          onChange={(e) => handleChange("modal", e.target.value)}
        />
      </Modal>

      <Modal
        title="Add Unit"
        visible={modalUnit}
        onOk={handleOkUnit}
        onCancel={handleCancelUnit}
        closable
      >
        <Input
          value={data.modal}
          placeholder="Add Unit "
          onChange={(e) => handleChange("modal", e.target.value)}
        />
      </Modal>
      <ul className="breadcumb">
        <li>
          <Link to="/item">Item</Link>
        </li>
        <li className="active">{match.params.id ? "Edit Item" : "Add Item"}</li>
      </ul>
      <div className="content">
        <Form onSubmit={handleSave}>
          <Row>
            <Col span={17} offset={1}>
              <Col span={12}></Col>
              <Col md={4} xs={20}>
                <div style={{ marginLeft: 17 }} className="avatar-form">
                  <div className="mb-20">
                    <Avatar
                      src={
                        data.images_url
                          ? data.images_url
                          : data.images
                          ? URL.createObjectURL(data.images)
                          : profile
                      }
                      size={100}
                    />
                  </div>
                  <Upload
                    onRemove={handleRemove}
                    beforeUpload={handleBeforeUpload}
                    fileList={[]}
                    accept="image/jpg, image/png"
                  >
                    {data.images ? (
                      <Button type="danger" onClick={handleRemove}>
                        <i className="mdi mdi-close mr-5" />
                        Delete Foto
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        style={{ backgroundColor: "#069964" }}
                      >
                        <i className="mdi mdi-upload mr-5" />
                        Upload Foto
                      </Button>
                    )}
                  </Upload>
                </div>
              </Col>

              <Row style={{ marginTop: 15 }}>
                <Col span={8}>
                  <div className="form-group">
                    <label className="form-label">
                      Item Number <span className="error-text">*</span>
                    </label>
                    <Input
                      style={{ width: 420 }}
                      value={data.number}
                      className={errors && errors.number ? "is-error" : ""}
                      onChange={(e) => handleChange("number", e.target.value)}
                    />
                    {errors && errors.number && (
                      <span className="error-text">{errors.number[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Code <span className="error-text">*</span>
                    </label>
                    <Input
                      style={{ width: 420 }}
                      value={data.code}
                      className={errors && errors.code ? "is-error" : ""}
                      onChange={(e) => handleChange("code", e.target.value)}
                    />
                    {errors && errors.code && (
                      <span className="error-text">{errors.code[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Area <span className="error-text">*</span>
                    </label>
                    <Input
                      style={{ width: 420 }}
                      value={data.area}
                      className={errors && errors.area ? "is-error" : ""}
                      onChange={(e) => handleChange("area", e.target.value)}
                    />
                    {errors && errors.area && (
                      <span className="error-text">{errors.area[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Std Stock <span className="error-text">*</span>
                    </label>
                    <Input
                      style={{ width: 420 }}
                      value={data.std_stock}
                      className={errors && errors.std_stock ? "is-error" : ""}
                      onChange={(e) =>
                        handleChange("std_stock", e.target.value)
                      }
                    />
                    {errors && errors.std_stock && (
                      <span className="error-text">{errors.std_stock[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Rank <span className="error-text">*</span>
                    </label>
                    <Select
                      style={{ width: 420 }}
                      labelInValue
                      onChange={(value) => handleChangeSelect("rank", value)}
                      onFocus={() => getRank()}
                      showSearch
                      onSearch={(value) => getRank(value)}
                      filterOption={false}
                      value={{
                        key: data.rank_id,
                        label: data.rank,
                      }}
                    >
                      {ranks &&
                        ranks.map((rank) => {
                          return (
                            <Option value={rank.name} key={rank.name}>
                              {rank.name}
                            </Option>
                          );
                        })}
                    </Select>
                    {errors && errors.rank && (
                      <span className="error-text">{errors.rank[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Maker <span className="error-text">*</span>
                    </label>
                    <Select
                      allowClear
                      style={{ width: 420 }}
                      labelInValue
                      onChange={(value) =>
                        handleChangeSelect("vendor", value, data._id)
                      }
                      onFocus={() => getVendor()}
                      showSearch
                      onSearch={(value) => getVendor(value)}
                      filterOption={false}
                      value={{
                        key: data.vendor_id,
                        label: data.vendor_name,
                      }}
                    >
                      {vendors &&
                        vendors.map((vendor) => {
                          return (
                            <Option value={vendor._id} key={vendor._id}>
                              {vendor.name}
                            </Option>
                          );
                        })}
                    </Select>
                    {errors && errors.vendor_name && (
                      <span className="error-text">
                        {errors.vendor_name[0]}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Name <span className="error-text">*</span>
                    </label>
                    <Input
                      style={{ width: 420 }}
                      value={data.name}
                      className={errors && errors.name ? "is-error" : ""}
                      onChange={(e) => handleChange("name", e.target.value)}
                    />
                    {errors && errors.name && (
                      <span className="error-text">{errors.name[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Category <span className="error-text">*</span>
                    </label>
                    <Select
                      className="form-label"
                      allowClear
                      labelInValue
                      style={{ width: 320 }}
                      onChange={(value) =>
                        handleChangeSelectCategory("category", value, data._id)
                      }
                      onFocus={() => getCategory()}
                      onSearch={(value) => getCategory(value)}
                      filterOption={false}
                      showSearch
                      value={{
                        key: data.category_id,
                        label: data.category_name,
                      }}
                    >
                      {categories &&
                        categories.map((category) => {
                          return (
                            <Option value={category._id} key={category._id}>
                              {category.name}
                            </Option>
                          );
                        })}
                      <Option value="new">+ Create new category</Option>
                    </Select>
                    {errors && errors.category_name && (
                      <span className="error-text">
                        {errors.category_name[0]}
                      </span>
                    )}
                    {data.category_name ? (
                      <Button
                        type="primary"
                        style={{
                          marginLeft: 5,
                          marginRight: -300,
                          backgroundColor: "#069964",
                          borderColor: "#069964",
                        }}
                        onClick={() => handleDeleteCategories()}
                      >
                        Delete
                        <CloseOutlined />
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <Col span={8} offset={8}>
                  <div className="form-group">
                    <label className="form-label" style={{ marginRight: 150 }}>
                      Unit <span className="error-text">*</span>
                    </label>
                    <Select
                      allowClear
                      labelInValue
                      style={{ width: 320 }}
                      onChange={(value) =>
                        handleChangeSelectUnit("unit", value, data._id)
                      }
                      onFocus={() => getUnit()}
                      showSearch
                      onSearch={(value) => getUnit(value)}
                      filterOption={false}
                      value={{
                        key: data.unit_id,
                        label: data.unit_name,
                      }}
                    >
                      {units &&
                        units.map((unit) => {
                          return (
                            <Option value={unit._id} key={unit._id}>
                              {unit.name}
                            </Option>
                          );
                        })}
                      <Option value="new">+ Create new unit</Option>
                    </Select>
                    {errors && errors.unit_name && (
                      <span className="error-text">{errors.unit_name[0]}</span>
                    )}
                    {data.unit_name ? (
                      <Button
                        type="primary"
                        style={{
                          marginLeft: 5,
                          marginRight: -300,
                          backgroundColor: "#069964",
                          borderColor: "#069964",
                        }}
                        onClick={() => handleDeleteUnits()}
                      >
                        Delete
                        <CloseOutlined />
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Price <span className="error-text">*</span>
                    </label>
                    <Input
                      style={{ width: 420, textAlign: "right" }}
                      value={data.price}
                      className={errors && errors.price ? "is-error" : ""}
                      onChange={(e) => handleChange("price", e.target.value)}
                    />
                    {errors && errors.price && (
                      <span className="error-text">{errors.price[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Stock Min <span className="error-text">*</span>
                    </label>
                    <Input
                      style={{ width: 420, textAlign: "right" }}
                      value={data.stock_min}
                      className={errors && errors.stock_min ? "is-error" : ""}
                      onChange={(e) =>
                        handleChange("stock_min", e.target.value)
                      }
                    />
                    {errors && errors.stock_min && (
                      <span className="error-text">{errors.stock_min[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Stock Max <span className="error-text">*</span>
                    </label>
                    <Input
                      style={{ width: 420, textAlign: "right" }}
                      value={data.stock_max}
                      className={errors && errors.stock_max ? "is-error" : ""}
                      onChange={(e) =>
                        handleChange("stock_max", e.target.value)
                      }
                    />
                    {errors && errors.stock_max && (
                      <span className="error-text">{errors.stock_max[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Quantity <span className="error-text">*</span>
                    </label>
                    <Input
                      style={{ width: 420, textAlign: "right" }}
                      value={data.quantity}
                      className={errors && errors.quantity ? "is-error" : ""}
                      onChange={(e) => handleChange("quantity", e.target.value)}
                    />
                    {errors && errors.quantity && (
                      <span className="error-text">{errors.quantity[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Type <span className="error-text">*</span>
                    </label>
                    <Input
                      style={{ width: 420 }}
                      value={data.type}
                      className={errors && errors.type ? "is-error" : ""}
                      onChange={(e) => handleChange("type", e.target.value)}
                    />
                    {errors && errors.type && (
                      <span className="error-text">{errors.type[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Used <span className="error-text">*</span>
                    </label>
                    <Input
                      style={{ width: 420 }}
                      value={data.used}
                      className={errors && errors.used ? "is-error" : ""}
                      onChange={(e) => handleChange("used", e.target.value)}
                    />
                    {errors && errors.used && (
                      <span className="error-text">{errors.used[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Status Part <span className="error-text">*</span>
                    </label>
                    {console.log(data)}
                    <Select
                      style={{ width: 420 }}
                      onChange={(value) => handleChange("status_part", value)}
                      value={data.status_part}
                    >
                      <Option value="baru">Baru</Option>
                      <Option value="eks_trial">Eks Trial</Option>
                      <Option value="overhoul">Overhoul</Option>
                      <Option value="reuse">Reuse</Option>
                    </Select>

                    {errors && errors.status_part && (
                      <span className="error-text">
                        {errors.status_part[0]}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Col sm={24}>
                <Form label="Material">
                  <Button
                    style={{
                      backgroundColor: "#069964",
                      borderColor: "#069964",
                    }}
                    onClick={handleAddRow}
                    type="primary"
                  >
                    Add new row
                  </Button>

                  <Table
                    style={{ marginTop: 30 }}
                    rowKey={(row) => row.id}
                    columns={[
                      {
                        dataIndex: "unit_name",
                        title: "Unit",
                        sorter: false,
                        render: (unit_name, item) => {
                          return (
                            <Select
                              style={{ width: 150 }}
                              loading={itemsLoading}
                              labelInValue
                              onChange={(value) =>
                                handleChangeSelectRow("unit", value, item.id)
                              }
                              onFocus={() => getUnit("", "row")}
                              showSearch
                              onSearch={(value) => getUnit(value, "row")}
                              filterOption={false}
                              value={{
                                key: item.unit_id,
                                label: unit_name,
                              }}
                            >
                              {units &&
                                units.map((unit) => {
                                  return (
                                    <Option value={unit._id} key={unit._id}>
                                      {unit.name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          );
                        },
                      },
                      {
                        dataIndex: "conversion",
                        title: "Conversion",
                        sorter: false,
                        render: (conversion, item) => (
                          <Input
                            style={{ width: 400, textAlign: "right" }}
                            value={conversion}
                            onChange={(e) =>
                              handleChangeRow(
                                "conversion",
                                e.target.value,
                                item.id
                              )
                            }
                          />
                        ),
                      },
                      {
                        title: "",
                        dataIndex: "_id",
                        align: "right",
                        sorter: false,
                        fixed: "right",
                        width: 100,
                        render: (id) => {
                          return (
                            <Popconfirm
                              title="Sure to delete?"
                              onConfirm={() => handleDelete(id)}
                            >
                              <a style={{ color: "#069964" }}>Delete</a>
                            </Popconfirm>
                          );
                        },
                      },
                    ]}
                    dataSource={itemunits}
                    pagination={false}
                  />
                </Form>
              </Col>

              <div className="form-group">
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{ backgroundColor: "#069964", borderColor: "#069964" }}
                >
                  Submit
                </Button>

                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  style={{
                    backgroundColor: "#069964",
                    marginLeft: 10,
                    borderColor: "#069964",
                    color: "white",
                  }}
                >
                  Reset
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default ItemForm;
