import { message, Form, Input } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from "../../assets/logo_sugityy.png";
import axios from "axios";
import React, { useState } from "react";
import { Redirect } from "react-router";
import Button from "../../components/Button/Button";
import { url } from "../../constant/url";
import "./Login.css";

function Login({ history }) {
  const [login, setLogin] = useState({ username: "", password: "" });
  const [focus, setFocus] = useState({
    username: false,
    password: false,
  });
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({
    username: null,
    password: null,
  });

  const handleChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: null,
    });

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleLogin = () => {
    setLoading(true);
    axios
      .post(`${url}/login`, {
        username: data.username,
        password: data.password,
      })
      .then((res) => {
        message.success(res.data.message);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("data", JSON.stringify(res.data.data));
        localStorage.setItem("menus", JSON.stringify(res.data.menus));
        localStorage.setItem("fullname", res.data.data.full_name);
        localStorage.setItem("active", res.data.menus[0].name);
        localStorage.setItem("avatar", res.data.data.photo ? res.data.data.photo : "");
        history.push("dashboard");
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          setError(err.response.data.errorDetails);
          setErrors(err.response.data.errorDetails);
          if(err.response.data.type){
            message.error(err.response.data.message);
          } {
            if(err.response.data.errors.password) {
              message.error(err.response.data.errors.password);
            }
            if(err.response.data.errors.username) {
              message.error(err.response.data.errors.username);
            }
          }
        } else {
          message.error(err);
        }
        setLoading(false);
      });
    console.log(errors);
  };

  const handleFocus = (name) => {
    setFocus({
      ...focus,
      [name]: true,
    });
  };

  const handleBlur = (name) => {
    setFocus({
      ...focus,
      [name]: false,
    });
  };

  return !localStorage.getItem("token") ? (
    <div className="login">
      <img
        src={logo}
        className="kelola-logo"
        alt="logo"
        style={{
          display: "flex",
          marginBottom: 105,
          margin: "auto",
          width: 350,
          height: 100,
        }}
      />

      <Form onFinish={handleLogin}>
        <Form.Item
          validateStatus={error && error[0].username ? "error" : false}
          help={error && error[0].username ? error[0].username : false}
        >
          <Input
            style={{ marginBottom: 2 }}
            className="login-input"
            prefix={<UserOutlined />}
            placeholder="Username"
            onChange={(e) => handleChange("username", e.target.value)}
            value={data.username}
          />
        </Form.Item>
        <Form.Item
          validateStatus={error && error[0].password ? "error" : false}
          help={error && error[0].password ? error[0].password : false}
        >
          <Input
            className="login-input"
            prefix={<LockOutlined />}
            type="password"
            placeholder="Password"
            onChange={(e) => handleChange("password", e.target.value)}
            value={data.password}
          />
        </Form.Item>
        <div>
          <Button
            htmlType="submit"
            loading={loading}
            type="primary"
            className="button-login"
          >
            Login
          </Button>
        </div>
      </Form>
    </div>
  ) : (
    <Redirect to="/dashboard" />
  );
}

export default Login;
