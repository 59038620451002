import { Button, Col, Input, message, Row, Select, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./KanbanForm.css";
import axios from "axios";
import { url }  from "../../constant/url";

function KanbanForm({ history, match }) {
  const [data, setData] = useState({
    code: "",
    area: "",
    std_stock: "",
    vendor_id: "",
    vendor_name: "",
    item_id: "",
    item_name: "",
    quantity: "",
    qty_update: "",
    type: "",
    used: "",
    status_part: "",
  });

  const [page] = useState(1);
  const [perpage] = useState(10);
  const [sort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [items, setItems] = useState([]);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const { Content } = Layout;
  const { Option } = Select;

  useEffect(() => {
    if (match.params.id) {
      getData();
    }
  }, []);

  const handleChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });

    setData({
      ...data,
      [name]: value,
    });
  };

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/kanban/${match.params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const {
          code,
          area,
          std_stock,
          vendor_id,
          vendor_name,
          item_id,
          item_name,
          quantity,
          qty_update,
          type,
          used,
          status_part,
          permissions,
        } = res.data.data;
        setData({
          ...data,
          code,
          area,
          std_stock,
          vendor_id,
          vendor_name,
          item_id,
          item_name,
          quantity,
          qty_update,
          type,
          used,
          status_part,
          permissions,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getItems = async (keyword, type) => {
    setItemsLoading(true);

    await axios
      .get(`${url}/item`, {
        params: {
          type: type,
          limit: perpage,
          page,
          sortBy: `${sort.sort}:${sort.order}`,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setItems(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setItemsLoading(false);
      });
  };

  const getVendor = async (keyword, type) => {
    setItemsLoading(true);

    await axios
      .get(`${url}/vendor`, {
        params: {
          keyword,
          columns: ["vendor_id", "vendor_name"],
          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setVendors(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setItemsLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      code: "",
      area: "",
      std_stock: "",
      vendor_id: "",
      vendor_name: "",
      item_id: "",
      item_name: "",
      quantity: "",
      qty_update: "",
      type: "",
      used: "",
      status_part: "",
    });
  };

  const handleChangeSelect = (name, value) => {
    setErrors(null);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_name`]: value.label,
    });
  };

  const handleChangeSelectItem = (name, value) => {
    setErrors(null);

    if (name === "item") {
      const findItem = items.find((item) => item.id === value.value);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(data);
    axios
      .post(
        match.params.id ? `${url}/kanban/${match.params.id}` : `${url}/kanban`,
        {
          code: data.code,
          area: data.area,
          std_stock: data.std_stock,
          vendor_id: data.vendor_id,
          vendor_name: data.vendor_name,
          item_id: data.item_id,
          item_name: data.item_name,
          quantity: data.quantity,
          type: data.type,
          used: data.used,
          status_part: data.status_part,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        message.success(res.data.message);
        history.push("/kanban");
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
          message.error(err.response.data.message);
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/kanban">Kanban</Link>
        </li>
        <li className="active">
          {match.params.id ? "Edit Kanban" : "Add Kanban"}
        </li>
      </ul>
      <div className="content">
        <form onSubmit={handleSave}>
          <Row>
            <Col span={17}>
              <Col span={12}></Col>

              <Row>
                <Col span={8}>
                  <div className="form-group">
                    <label className="form-label">
                      Code <span className="error-text">*</span>
                    </label>
                    <Input
                      style={{ width: 420 }}
                      value={data.code}
                      className={errors && errors.code ? "is-error" : ""}
                      onChange={(e) => handleChange("code", e.target.value)}
                    />
                    {errors && errors.code && (
                      <span className="error-text">{errors.code[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Area <span className="error-text">*</span>
                    </label>
                    <Input
                      style={{ width: 420 }}
                      value={data.area}
                      className={errors && errors.area ? "is-error" : ""}
                      onChange={(e) => handleChange("area", e.target.value)}
                    />
                    {errors && errors.area && (
                      <span className="error-text">{errors.area[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Std Stock <span className="error-text">*</span>
                    </label>
                    <Input
                      style={{ width: 420 }}
                      value={data.std_stock}
                      className={errors && errors.std_stock ? "is-error" : ""}
                      onChange={(e) =>
                        handleChange("std_stock", e.target.value)
                      }
                    />
                    {errors && errors.std_stock && (
                      <span className="error-text">{errors.std_stock[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Maker <span className="error-text">*</span>
                    </label>
                    <Select
                      style={{ width: 420 }}
                      labelInValue
                      onChange={(value) =>
                        handleChangeSelect("vendor", value, data._id)
                      }
                      onFocus={() => getVendor()}
                      showSearch
                      onSearch={(value) => getVendor(value)}
                      filterOption={false}
                      value={{
                        key: data.vendor_id,
                        label: data.vendor_name,
                      }}
                    >
                      {vendors &&
                        vendors.map((vendor) => {
                          return (
                            <Option value={vendor._id} key={vendor._id}>
                              {vendor.name}
                            </Option>
                          );
                        })}
                    </Select>
                    {errors && errors.vendor_name && (
                      <span className="error-text">
                        {errors.vendor_name[0]}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">Item</label>
                    <Select
                      style={{ width: 420 }}
                      labelInValue
                      onChange={(value) =>
                        handleChangeSelectItem("item", value, data._id)
                      }
                      onFocus={() => getItems("")}
                      showSearch
                      onSearch={(value) => getItems(value)}
                      filterOption={false}
                      value={{
                        key: data.item_id,
                        label: data.item_name,
                      }}
                    >
                      {items &&
                        items.map((item) => {
                          return (
                            <Option value={item._id} key={item._id}>
                              {item.name}
                            </Option>
                          );
                        })}
                    </Select>
                    {errors && errors.item_name && (
                      <span className="error-text">{errors.item_name[0]}</span>
                    )}
                  </div>
                </Col>

                <Col span={8} offset={8}>
                  <div className="form-group">
                    <label className="form-label">
                      Quantity <span className="error-text">*</span>
                    </label>
                    <Input
                      style={{ width: 420, textAlign: "right" }}
                      value={data.quantity}
                      className={errors && errors.quantity ? "is-error" : ""}
                      onChange={(e) => handleChange("quantity", e.target.value)}
                    />
                    {errors && errors.quantity && (
                      <span className="error-text">{errors.quantity[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Type <span className="error-text">*</span>
                    </label>
                    <Input
                      style={{ width: 420 }}
                      value={data.type}
                      className={errors && errors.type ? "is-error" : ""}
                      onChange={(e) => handleChange("type", e.target.value)}
                    />
                    {errors && errors.type && (
                      <span className="error-text">{errors.type[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Used <span className="error-text">*</span>
                    </label>
                    <Input
                      style={{ width: 420 }}
                      value={data.used}
                      className={errors && errors.used ? "is-error" : ""}
                      onChange={(e) => handleChange("used", e.target.value)}
                    />
                    {errors && errors.used && (
                      <span className="error-text">{errors.used[0]}</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Status Part <span className="error-text">*</span>
                    </label>
                    {console.log(data)}
                    <Select
                      style={{ width: 420 }}
                      onChange={(value) => handleChange("status_part", value)}
                      value={data.status_part}
                    >
                      <Option value="baru">Baru</Option>
                      <Option value="eks_trail">Eks Trail</Option>
                      <Option value="overhoul">Overhoul</Option>
                      <Option value="reuse">Reuse</Option>
                    </Select>

                    {errors && errors.status_part && (
                      <span className="error-text">
                        {errors.status_part[0]}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>

              <div className="form-group">
                <Button
                  type="primary"
                  onClick={handleSave}
                  loading={loading}
                  style={{ backgroundColor: "#069964", borderColor: "#069964" }}
                >
                  Submit
                </Button>

                <Button
                  htmlType="reset"
                  type="primary"
                  onClick={handleReset}
                  style={{
                    backgroundColor: "#069964",
                    marginLeft: 10,
                    borderColor: "#069964",
                  }}
                >
                  Reset
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
}

export default KanbanForm;
