import {
  Button,
  Col,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Row,
  // Table,
  Upload,
  Card,
  Space,
  Select,
  Tooltip
} from "antd";
import Table from "antd/lib/table";
import { Redirect } from "react-router";
import axios from "axios";
import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { url }  from "../../constant/url";
import profile from "../../assets/item.png";
import "./Item.css";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExportOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import fileDownload from "js-file-download";
import moment from "moment";
import WidthContext from "../../context/WidthContext";
const { Option } = Select;

function Item({ history }) {
  // const { isCollapsed } = useContext(WidthContext);

  const getItems = () => {
    const columns = [
      {
        title: "Warehouse Name",
        dataIndex: "warehouse_name",
        sorter: false,
      },

      {
        title: "Lemari Name",
        dataIndex: "lemari_name",
        sorter: false,
      },

      {
        title: "Rack Name",
        dataIndex: "rack_name",

        sorter: false,
      },
      {
        title: "Total",
        dataIndex: "total",
        sorter: false,
      },
      {
        title: "Action",
        sorter: false,
        render: (data) => {
          return (
            <>
              <Tooltip placement="top" title={`Delete ${data.lemari_name}`}>
                <span onClick={() => handleDeletePartDetails(data)} style={{cursor:"pointer"}} >
                  <DeleteOutlined 
                    style={{fontSize: 20, color: "red"}}
                  />
                </span>
              </Tooltip>
            </>
          );
        },
      },
    ];

    if (!localStorage.getItem("token")) {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <Table
          size="large"
          columns={columns}
          rowKey={(data, index) => index}
          loading={loadingItems}
          dataSource={manifestItems}
          pagination={{
            pageSize: perPageDetail,
            current: pageDetail,
            total: manifestItemsTotal,
            showSizeChanger: true,

            style: {
              marginTop: "2rem",
              marginBottom: 0,
            },
          }}
          onChange={handleChangeItemsTable}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      sorter: false,
      width: 100,
      fixed: "left",
      render: (image_url) =>
        image_url ? (
          <img style={{ width: 80, height: 70 }} src={image_url} />
        ) : (
          <img style={{ width: 80, height: 70 }} src={profile} />
        ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: false,
    },
    {
      title: " Item Number",
      dataIndex: "number",
      sorter: false,
      width: 90,
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      sorter: false,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      sorter: false,
    },
    {
      title: "Std Stock",
      dataIndex: "std_stock",
      key: "std_stock",
      sorter: false,
    },
    {
      title: "Stock Min",
      dataIndex: "stock_min",
      key: "stock_min",
      sorter: false,
    },
    {
      title: "Stock Max",
      dataIndex: "stock_max",
      key: "stock_max",
      sorter: false,
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: false,
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
      sorter: false,
    },

    {
      title: "Maker",
      dataIndex: "vendor_name",
      key: "vendor_name",
      sorter: false,
    },

    {
      title: "Category ",
      dataIndex: "category_name",
      key: "category_name",
      sorter: false,
    },
    {
      title: "Unit",
      dataIndex: "unit_name",
      key: "unit_name",
      sorter: false,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: false,
    },

    {
      title: "Used",
      dataIndex: "used",
      key: "used",
      sorter: false,
    },
    {
      title: "Status Part",
      dataIndex: "status_part",
      key: "status_part",
      sorter: false,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "date",
      sorter: false,
      render: (update_at) => moment(update_at).format("DD MMMM YYYY HH:mm"),
    },

    {
      title: "",
      dataIndex: "_id",
      key: "id",
      sorter: false,
      fixed: "right",
      width: 50,
      render: (id, data) => {
        return (
          <>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link onClick={() => downloadTemplateQR(data)}>
                      <ExportOutlined /> Print QR
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Link onClick={() => download(data)}>
                      <ExportOutlined /> Print Mutasi
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <Link to={`/item/edit/${id}`}>
                      <EditOutlined /> Edit
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="4">
                    <span onClick={() => handleDelete(id)}>
                      <DeleteOutlined />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <i className="mdi mdi-dots-vertical" />
              </span>
            </Dropdown>
          </>
        );
      },
    },
  ];

  const [total, setTotal] = useState([]);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });
  const [loading, setLoading] = useState(false);
  const [loadingAll, setLoadingAll] = useState(false);
  const [rowItems, setRowItems] = useState([]);
  const [loadingItems, setLoadingItems] = useState(false);
  const [keywordItems, setKeywordItems] = useState("");
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [printing, setPrinting] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [manifestItems, setManifestItems] = useState([]);
  const [manifestItemsTotal, setManifestItemsTotal] = useState(0);
  const [error, setError] = useState(null);
  const [item, setItem] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [racks, setRacks] = useState([]);
  const [lemaris, setLemaris] = useState([]);
  const [ranks, setRanks] = useState([]);
  const [data, setData] = useState({
    file: null,
  });

  const [filter, setFilter] = useState({
    warehouse_id: "",
    warehouse_name: "",
    lemari_id: "",
    lemari_name: "",
    rack_id: "",
    rack_name: "",
    rank_id: "",
    rank_name: "",
  });

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  useEffect(() => {
    getData();
  }, [keyword, page, perpage, sort, filter]);

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/item`, {
        params: {
          keyword,
          columns: [
            "number",
            "code",
            "area",
            "std_stock",
            "rank",
            "vendor_id",
            "vendor_name",
            "name",
            "category_id",
            "category_name",
            "unit_id",
            "unit_name",
            "item_id",
            "item_name",
            "price",
            "stock_min",
            "stock_max",
            "quantity",
            "used",
            "type",
            "status_part",
          ],
          perpage: perpage,
          page,
          sort: sort.sort,
          order: sort.order,
          warehouse_id: filter.warehouse_id,
          lemari_id: filter.lemari_id,
          rack_id: filter.rack_id,
          rank: filter.rank_name,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        res.data.data.forEach(x => {
          if(x.quantity < x.stock_min ) {
            x['color_warning'] = 'warning-stock';
          }else if(x.item_detail.length == 0){
            x['color_warning'] = 'no-detail';
          } else {
           
            x['color_warning'] = '';
          }
        });

        setItem(res.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (error.response) {
          message.error(error.response.data.message);
          history.push("/login");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeItems = (data) => {
    const index = data[data.length - 1];
    setRowItems([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getManifestItems(data2[0]);
      console.log(data2[0])
    }
  };

  const getManifestItems = (id = selectedId) => {
    setLoadingItems(true);
    axios
      .get(`${url}/item/details/${id}`, {
        params: {
          keyword: keywordItems,
          columns: ["warehouse_name", "lemari_name", "rack_name", "total"],
          perpage: perPageDetail,
          page: pageDetail,
          warehouse_id: filter.warehouse_id,
          lemari_id: filter.lemari_id,
          rack_id: filter.rack_id,
          rank: filter.rank_name,
        },

        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setManifestItems(res.data.data);
        setManifestItemsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet started");
        }
      })
      .finally(() => {
        setLoadingItems(false);
      });
  };

  const handleChangeItemsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setPerPageDetail(pagination.pageSize);
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure ?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const handleDeletePartDetails = (data) => {
    Modal.confirm({
      title: "Are you sure ?",
      content: (
        <div>
          <p>Deleted data {data.lemari_name} cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDeletePartDetails(data._id, data);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    axios
      .delete(`${url}/item/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const confirmDeletePartDetails = (id, data) => {
    setLoading(true);
    axios
      .delete(`${url}/item-details/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        getManifestItems(data.item_id);
        // getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPerpage(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const downloadTemplateQR = (data) => {
    setPrinting(true);

    let code = data.code;
    axios
      .post(
        `${url}/item/print-qr`,
        {
          code,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        var blob = new Blob([res.data], { type: "application/pdf" });
        var blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      })
      .finally(() => {
        setPrinting(false);
      });
  };

  const downloadTemplatePdf = (data) => {
    setPrinting(true);
    setLoading(true);
    axios
      .post(
        `${url}/item/kartu-stock`,
        {
          data,
        },
        {
          params: {
            keyword,
            columns: [
              "number",
              "code",
              "area",
              "std_stock",
              "rank",
              "vendor_id",
              "vendor_name",
              "name",
              "category_id",
              "category_name",
              "unit_id",
              "unit_name",
              "item_id",
              "item_name",
              "price",
              "stock_min",
              "stock_max",
              "quantity",
              "used",
              "type",
              "status_part",
            ],
            perpage: perpage,
            page,
            sort: sort.sort,
            order: sort.order,
            warehouse_id: filter.warehouse_id,
            lemari_id: filter.lemari_id,
            rack_id: filter.rack_id,
            rank: filter.rank_name,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        var blob = new Blob([res.data], { type: "application/pdf" });
        var blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      })
      .finally(() => {
        setPrinting(false);
        setLoading(false);
      });
  };

  const downloadTemplateAllKanban = (data) => {
    setLoadingAll(true);
    setPrinting(true);

    axios
      .post(
        `${url}/item/print-all-qr`,
        { data },
        {
          params: {
            perpage: perpage,
            page,
            sort: sort.sort,
            order: sort.order,
            warehouse_id: filter.warehouse_id,
            lemari_id: filter.lemari_id,
            rack_id: filter.rack_id,
            rank: filter.rank_name,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        var blob = new Blob([res.data], { type: "application/pdf" });
        var blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      })
      .finally(() => {
        setPrinting(false);
        setLoadingAll(false);
      });
  };

  const download = (data) => {
    setPrinting(true);

    let item_id = data._id;
    let number = data.number;
    axios
      .post(
        `${url}/item/print-mutasi`,
        {
          number,
          item_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        var blob = new Blob([res.data], { type: "application/pdf" });
        var blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      })
      .finally(() => {
        setPrinting(false);
      });
  };

  const downloadTemplate = () => {
    setLoading(true);
    axios
      .get(`${url}/item/download`, {
        params: {
          keyword,
          columns: [
            "number",
            "code",
            "area",
            "rank",
            "std_stock",
            "vendor_id",
            "vendor_name",
            "name",
            "category_id",
            "category_name",
            "unit_id",
            "unit_name",
            "item_id",
            "item_name",
            "price",
            "stock_min",
            "stock_max",
            "quantity",
            "used",
            "type",
            "status_part",
          ],
          perpage: perpage,
          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, "export_item.xlsx");
      });
    setLoading(false);
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    axios
      .post(`${url}/item/import`, fd, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const getWarehouse = async (keyword, type) => {
    await axios
      .get(`${url}/warehouse/list`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setWarehouses(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {});
  };

  const handleChangeViewWarehouse = (name, value) => {
    setError(null);

    if (value) {
      setFilter({
        ...filter,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setFilter({
        ...filter,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const getRack = async (keyword, type) => {
    await axios
      .get(`${url}/rack/list`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setRacks(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {});
  };

  const handleChangeViewRack = (name, value) => {
    setError(null);

    if (value) {
      setFilter({
        ...filter,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setFilter({
        ...filter,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const getLemari = async (keyword, type) => {
    await axios
      .get(`${url}/lemari/list`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLemaris(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {});
  };

  const handleChangeViewLemari = (name, value) => {
    setError(null);

    if (value) {
      setFilter({
        ...filter,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setFilter({
        ...filter,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const getRank = async (keyword, type) => {
    await axios
      .get(`${url}/settings/find`, {
        params: {
          variable: "RANK",
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setRanks(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {});
  };

  const handleChangeViewRank = (name, value) => {
    setError(null);

    if (value) {
      setFilter({
        ...filter,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setFilter({
        ...filter,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
  };

  const showTotal = (total) => {
    return `Found total ${total} record`;
  };

  return localStorage.getItem("token") ? (
    <div>
      <ul className="breadcumb">
        <li className="active">Item</li>
      </ul>
      <div className="content">
        <Row justify="space-between">
          <Col>
            <Link to="/item/create">
              <Button
                type="primary"
                style={{ backgroundColor: "#069964", borderColor: "#069964" }}
              >
                <PlusOutlined />
                Add
              </Button>
            </Link>
            <Modal
              title="Import Item"
              visible={isModalVisible}
              footer={null}
              onCancel={closeModal}
              // onCancel={handleCancel}
            >
              <Card className="body-data">
                <div name="control-hooks">
                  <div name="file" label="File">
                    <Fragment>
                      <Upload
                        onRemove={handleRemove}
                        beforeUpload={handleBeforeUpload}
                        fileList={[]}
                      >
                        <Button style={{ marginBottom: 10 }}>
                          Choose File <ExportOutlined />
                        </Button>
                      </Upload>
                      {data.file && (
                        <React.Fragment
                          children={[
                            <Button onClick={handleRemove} type="danger" ghost>
                              <DeleteOutlined />
                            </Button>,
                          ]}
                        >
                          {data.file.name ? data.file.name : data.file}
                        </React.Fragment>
                      )}
                    </Fragment>
                  </div>
                  <div>
                    <Button
                      loading={loading}
                      onClick={onSaveData}
                      type="primary"
                      htmlType="submit"
                      className="mr-button"
                      style={{
                        marginRight: 10,
                        backgroundColor: "#069964",
                        borderColor: "#069964",
                      }}
                    >
                      Upload
                    </Button>
                    <Button htmlType="reset" onClick={handleRemove}>
                      Reset
                    </Button>
                  </div>
                </div>
              </Card>
            </Modal>
            <Button
              onClick={showModal}
              type="primary"
              style={{
                backgroundColor: "#069964",
                borderColor: "#069964",
                marginLeft: 10,
              }}
            >
              <ImportOutlined />
              Import
            </Button>
            <Button
              onClick={downloadTemplate}
              type="primary"
              style={{
                backgroundColor: "#069964",
                borderColor: "#069964",
                marginLeft: 10,
              }}
            >
              <ExportOutlined />
              Export
            </Button>
            <Button
              loading={loadingAll}
              onClick={() => downloadTemplateAllKanban(data)}
              type="primary"
              style={{
                backgroundColor: "#069964",
                borderColor: "#069964",
                marginLeft: 10,
              }}
            >
              <ExportOutlined />
              Print All Kanban
            </Button>
          </Col>

          <Col xs={24} md={24} lg={6} className="align-right">
            <Button
              type="primary"
              style={{
                backgroundColor: "#069964",
                borderColor: "#069964",
                marginLeft: 140,
              }}
              onClick={() => downloadTemplatePdf(data)}
            >
              <ExportOutlined />
              Report
            </Button>

            <Input.Search
              style={{ marginTop: 10, marginBottom: 15 }}
              allowClear="true"
              onSearch={(value) => setKeyword(value)}
              placeholder="Search..."
            />
          </Col>
        </Row>

        {/* <Row type="flex" gutter={[12, 24]} justify="space-around">
          <Col></Col>

          <Space direction="vertical" size={12}>
            <Select
              allowClear="true"
              placeholder=" Warehouse"
              style={{ width: 200 }}
              labelInValue
              onChange={(value) =>
                handleChangeViewWarehouse("warehouse", value)
              }
              onFocus={() => getWarehouse("")}
              showSearch
              onSearch={(value) => getWarehouse(value)}
              filterOption={false}
            >
              {warehouses &&
                warehouses.map((warehouse) => {
                  return (
                    <Option value={warehouse._id} key={warehouse.name}>
                      {warehouse.name}
                    </Option>
                  );
                })}
            </Select>
          </Space>
          <Space direction="vertical" size={12}>
            <Select
              allowClear="true"
              placeholder=" Lemari"
              style={{ width: 200 }}
              labelInValue
              onChange={(value) => handleChangeViewLemari("lemari", value)}
              onFocus={() => getLemari("")}
              showSearch
              onSearch={(value) => getLemari(value)}
              filterOption={false}
            >
              {lemaris &&
                lemaris.map((lemari) => {
                  return (
                    <Option value={lemari._id} key={lemari.name}>
                      {lemari.name}
                    </Option>
                  );
                })}
            </Select>
          </Space>
          <Space direction="vertical" size={12}>
            <Select
              allowClear="true"
              placeholder=" Rack"
              style={{ width: 200 }}
              labelInValue
              onChange={(value) => handleChangeViewRack("rack", value)}
              onFocus={() => getRack("")}
              showSearch
              onSearch={(value) => getRack(value)}
              filterOption={false}
            >
              {racks &&
                racks.map((rack) => {
                  return (
                    <Option value={rack._id} key={rack.name}>
                      {rack.name}
                    </Option>
                  );
                })}
            </Select>
          </Space>
          <Space direction="vertical" size={12}>
            <Select
              allowClear="true"
              placeholder=" Rank"
              style={{ width: 200 }}
              labelInValue
              onChange={(value) => handleChangeViewRank("rank", value)}
              onFocus={() => getRank("")}
              showSearch
              onSearch={(value) => getRank(value)}
              filterOption={false}
            >
              {ranks &&
                ranks.map((rank) => {
                  return (
                    <Option value={rank.id} key={rank.name}>
                      {rank.name}
                    </Option>
                  );
                })}
            </Select>
          </Space>
        </Row> */}

        <Row className="mt-40">
          {/* <Col xs={24}>
            <Table
              expandable={{
                expandedRowRender: getItems,
                onExpandedRowsChange: (data) => handleChangeItems(data),
                expandedRowKeys: rowItems,
              }}
              columns={columns}
              dataSource={item.data}
              pagination={{
                total,
                showSizeChanger: true,
                showTotal: showTotal,
              }}
              loading={loading}
              rowKey={(data) => data._id}
              onChange={handleTableChange}
              // style={{ maxWidth: isCollapsed ? 900 : 1200 }}
              // style={{ maxWidth: 1200 }}
              scroll={{ x: 1200 }}
            />
          </Col> */}

        </Row>
        <Col xs={24}>
          <Table
            bordered
            size="small"
            scroll={{ x: 2000 }}
            columns={columns}
            expandable={{
              expandedRowRender: getItems,
              onExpandedRowsChange: (data) => handleChangeItems(data),
              expandedRowKeys: rowItems,
            }}
            rowClassName={(data) => data.color_warning.replace('#', '')}
            dataSource={item.data}
            rowKey={(data) => data._id}
            onChange={handleTableChange}
            pagination={{
              total,
              showSizeChanger: true,
              showTotal: showTotal,
            }}
            loading={loading}
          />
        </Col>
      </div>
    </div>
  ) : (
    <Redirect to="/login" />
  );
}

export default Item;
