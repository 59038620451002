let url;
let url_profile;
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  //server sugity
  // url = "http://192.168.10.51:8001/api";
  // gcp
  // url = "https://api.sugity.kelola.biz/api";
  //local sendiri
  url = "http://127.0.0.1:8000/api";
  url_profile = "http://127.0.0.1:8000/";
  //ip ajoy
  // url = "http://192.168.1.19:8000/api";
} else {
  url = "https://api.sugity.kelola.biz/api";
  url_profile = "https://api.sugity.kelola.biz/";
  // url = "http://192.168.1.19:8000/api";
  // url = "http://127.0.0.1:8000/api";
  // url = "http://192.168.10.51:8001/api";
}

//example

export { url, url_profile };
