import { Button, Col, Form, Input, message, Modal, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import Nestable from "react-nestable";
import "./Menu.css";
import axios from "axios";
import { url }  from "../../constant/url";
import _ from "underscore";

function Menu(history, match) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [menus, setMenus] = useState([]);
  const [data, setData] = useState({
    name: "",
    icon: "",
    url: "",
    children: [],
    is_active: true,
    order_number: 0,
    id: "",
    role_id: "",
    role_name: "",
  });

  useEffect(() => {
    getMenu();
  }, []);

  const handleReset = () => {
    setData({
      name: "",
      icon: "",
      url: "",
      parent_id: null,
      parent_name: null,
      role_id: "",
      role_name: "",
    });
  };

  const getMenu = () => {
    axios
      .get(`${url}/menus`, {
        params: {},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setMenus(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        }
      });
  };

  const handleChange = (name, value) => {
    setErrors({
      ...errors,
      [name]: "",
    });

    setData({
      ...data,
      [name]: value,
    });
  };

  const handleOk = async (e) => {
    e.preventDefault();

    if (data.id) {
      setLoading(true);
      handleUpdate("all", data, data);
      setIsModalVisible(false);
      setLoading(false);
      handleReset();
    } else {
      axios
        .post(
          `${url}/menus`,
          {
            name: data.name,
            icon: data.icon,
            url: data.url,
            parent_id: data.parent_id,
            parent_name: data.parent_name,
            role_id: data.role_id,
            role_name: data.role_name,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          message.success(res.data.message);
          handleReset();
          getMenu();
          setIsModalVisible(false);
        })
        .catch((err) => {
          if (err.response) {
            setErrors(err.response.data.errorDetails);
            message.error(err.response.data.message);
          }
          setLoading(false);
        });
    }
  };

  const handleSaveMany = (bulk) => {
    axios
      .post(
        `${url}/menus/bulk`,
        {
          data: bulk,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {})
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errorDetails);
          message.error(err.response.data.message);
        }
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    handleReset();
  };

  const handleRemove = (item) => {
    Modal.confirm({
      title: "Are you sure ?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(item);
      },
    });
  };

  const checkRecursiveDelete = (children, item) => {
    const findMenuChild = children
      .map((menu) => {
        const findChildren = menu.children.find((x) => x.id === item.id);
        if (findChildren) {
          return { ...findChildren, parent_id: menu.id };
        } else {
          if (menu.children.length > 0) {
            return checkRecursiveDelete(menu.children, item);
          }
        }
      })
      .filter((x) => x);

    const findParent = children.find(
      (x) => x.id === findMenuChild[0].parent_id
    );
    if (findParent) {
      findParent.children = findParent.children.filter((x) => x.id !== item.id);
    }
    return children;
  };

  const confirmDelete = (item) => {
    let newMenus = [...menus];
    const findMenu = newMenus.find((x) => x.id === item.id);
    if (findMenu) {
      newMenus = newMenus.filter((x) => x.id !== item.id);
    } else {
      const findMenuChild = newMenus
        .map((menu) => {
          const findChildren = menu.children.find((x) => x.id === item.id);
          if (findChildren) {
            return { ...findChildren, parent_id: menu.id };
          } else {
            if (menu.children.length > 0) {
              return checkRecursiveDelete(menu.children, item);
            }
          }
        })
        .filter((x) => x);

      const findParent = newMenus.find(
        (x) => x.id === findMenuChild[0].parent_id
      );
      if (findParent) {
        findParent.children = findParent.children.filter(
          (x) => x.id !== item.id
        );
      }
    }
    handleSaveMany(newMenus);
    setMenus(newMenus);
  };

  const handleEdit = (item) => {
    setData({
      ...data,
      id: item.id,
      name: item.name,
      url: item.url,
      is_active: item.is_active,
      children: item.children,
      icon: item.icon,
      role_id: item.role_id,
      role_name: item.role_name,
    });

    setIsModalVisible(true);
  };

  const checkRecursiveMenu = (field, value, children, item) => {
    const findMenuChild = children
      .map((child) => {
        const findChildren = child.children.find((x) => x.id === item.id);
        if (findChildren) {
          return { ...findChildren, parent_id: child.id };
        } else {
          if (child.children.length > 0) {
            return checkRecursiveMenu(field, value, child.children, item);
          }
        }
      })
      .filter((x) => x);

    const findParent = children.find(
      (x) => x.id === findMenuChild[0].parent_id
    );
    if (findParent) {
      const newChildren = [...findParent.children];
      const findChildren = newChildren.find(
        (x) => x.id === findMenuChild[0].id
      );
      if (field === "all") {
        findChildren.name = value.name;
        findChildren.url = value.url;
        findChildren.icon = value.icon;
      } else {
        findChildren[field] = value;
      }
    }
    return children;
  };

  const handleUpdate = (field, value, item) => {
    const newMenus = [...menus];
    const findMenus = newMenus.find((x) => x.id === item.id);
    if (findMenus) {
      if (field === "all") {
        findMenus.name = value.name;
        findMenus.url = value.url;
        findMenus.icon = value.icon;
        findMenus.role_id = value.role_id;
        findMenus.role_name = value.role_name;
      } else {
        findMenus[field] = value;
      }
    } else {
      const findMenuChild = newMenus
        .map((menu) => {
          const findChildren = menu.children.find((x) => x.id === item.id);
          if (findChildren) {
            return { ...findChildren, parent_id: menu.id };
          } else {
            if (menu.children.length > 0) {
              return checkRecursiveMenu(
                field,
                value,
                menu.children,
                item,
                menu.id
              );
            }
          }
        })
        .filter((x) => x);

      const findParent = newMenus.find(
        (x) => x.id === findMenuChild[0].parent_id
      );
      if (findParent) {
        const newChildren = [...findParent.children];
        const findChildren = newChildren.find(
          (x) => x.id === findMenuChild[0].id
        );
        if (field === "all") {
          findChildren.name = value.name;
          findChildren.url = value.url;
          findChildren.icon = value.icon;
        } else {
          findChildren[field] = value;
        }
      }
    }
    handleSaveMany(newMenus);
    setMenus(newMenus);
  };

  const renderItem = ({ item }) => {
    return (
      <div className={`menu-wrapper ${!item.is_active ? "menu-disabled" : ""}`}>
        <div>
          {item.icon && <i className={`${item.icon} mr-5`} />} {item.name}
        </div>
        <div>
          <Tooltip
            placement="top"
            title={item.is_active ? "Disable" : "Enable"}
          >
            <Button
              className="mr-5"
              type="ghost"
              onClick={() => handleUpdate("is_active", !item.is_active, item)}
            >
              {item.is_active ? (
                <i className="mdi mdi-eye" />
              ) : (
                <i className="mdi mdi-eye-off" />
              )}
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Edit">
            <Button
              className="mr-5"
              type="ghost"
              onClick={() => handleEdit(item)}
            >
              <i className="mdi mdi-pencil" />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Remove">
            <Button type="ghost" onClick={() => handleRemove(item)}>
              <i className="mdi mdi-close" />
            </Button>
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        okText={data.id ? "Save Changes" : "Save"}
        okButtonProps={{ loading }}
        cancelButtonProps={{ hidden: loading }}
        title={data.id ? "Edit menu" : "Add New Menu"}
        visible={isModalVisible}
        onOk={(values) => handleOk(values)}
        onCancel={handleCancel}
      >
        <Form layout="vertical">
          <Form.Item label="Name" required>
            <Input
              placeholder="Name"
              value={data.name}
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Icon">
            <Input
              placeholder="Icon"
              value={data.icon}
              onChange={(e) => handleChange("icon", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="URL" required>
            <Input
              placeholder="URL"
              value={data.url}
              onChange={(e) => handleChange("url", e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>

      <ul className="breadcumb">
        <li className="active">Menu</li>
      </ul>
      <div className="content">
        <Row justify="space-between">
          <Col>
            <Button
              type="primary"
              style={{ backgroundColor: "#069964", borderColor: "#069964" }}
              onClick={() => setIsModalVisible(true)}
            >
              <PlusOutlined />
              Add
            </Button>
          </Col>
        </Row>
        <Row className="mt-40 mb-20">
          <Nestable
            className="menu-list mb-20"
            onChange={(data) =>
              handleSaveMany(
                data.map((data, index) => {
                  return { ...data, order_number: index };
                })
              )
            }
            items={_.sortBy(menus, (x) => x.order_number)}
            renderItem={renderItem}
          />
        </Row>
      </div>
    </div>
  );
}

export default Menu;
