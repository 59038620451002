import "./Button.css";

function Button({ children, type, loading }, props) {
  return loading ? (
    <button disabled className={`btn-disabled ${type}`}>
      <i className="mdi mdi-spin mdi-loading mr-5" />
      Loading...
    </button>
  ) : (
    <button {...props} className={`btn ${type}`}>
      {children}
    </button>
  );
}

export default Button;
