import { Button, Col, Input, message, Row, Table } from "antd";
import { Redirect } from "react-router";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { url }  from "../../constant/url";
import { PlusOutlined } from "@ant-design/icons";
import "./StockOpnameForm.css";
import moment from "moment";
import _ from "underscore";

function StockOpname({ history }) {
  const getItems = () => {
    const columns = [
      {
        title: "Item  ",
        dataIndex: "item_name",
        sorter: false,
      },
      {
        title: " Stock Min ",
        dataIndex: "item",
        sorter: false,
        render: (item) => {
          return item.stock_min;
        },
      },
      {
        title: " Stock Max ",
        dataIndex: "item",
        sorter: false,
        render: (item) => {
          return item.stock_max;
        },
      },
      {
        title: "Warehouse ",
        dataIndex: "warehouse_name",
        sorter: false,
      },
      {
        title: "Lemari ",
        dataIndex: "lemari_name",
        sorter: false,
      },
      {
        title: "Rack ",
        dataIndex: "rack_name",
        sorter: false,
      },
      {
        title: "Qty System ",
        dataIndex: "quantity_sistem",
        sorter: false,
      },
      {
        title: "Qty Actual ",
        dataIndex: "quantity_update",
        sorter: false,
      },
      {
        title: "Notes ",
        dataIndex: "notes",
        sorter: false,
      },
    ];

    if (!localStorage.getItem("token")) {
      return <Redirect to="/login" />;
    }
    return (
      <div>
        <Table
          size="large"
          columns={columns}
          rowKey={(data, index) => index}
          loading={loadingItems}
          dataSource={manifestItems}
          pagination={{
            pageSize: perPageDetail,
            current: pageDetail,
            total: manifestItemsTotal,
            showSizeChanger: true,

            style: {
              marginTop: "2rem",
              marginBottom: 0,
            },
          }}
          onChange={handleChangeItemsTable}
        />
      </div>
    );
  };
  const columns = [
    {
      title: "Date ",
      dataIndex: "date",
      key: "date",
      sorter: true,
      width: 100,
      render: (date) => moment(date).format("DD MMMM YYYY"),
    },
    {
      title: "Item Not Balance",
      dataIndex: "balance",
      key: "balance",
      width: 100,
      sorter: false,
    },
    {
      title: "Evidence",
      dataIndex: "image_url",
      key: "image_url",
      sorter: false,
      width: 100,
      render: (image_url) =>
        image_url && (
          <img style={{ width: 120, height: 100 }} src={image_url} />
        ),
    },
  ];

  const [data, setData] = useState([]);
  const [total, setTotal] = useState([]);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(10);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [manifestItems, setManifestItems] = useState([]);
  const [manifestItemsTotal, setManifestItemsTotal] = useState(0);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [loadingItems, setLoadingItems] = useState(false);
  const [rowItems, setRowItems] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [keywordItems, setKeywordItems] = useState("");

  useEffect(() => {
    getData();
  }, [keyword, page, perpage, sort]);

  const getData = () => {
    setLoading(true);
    axios
      .get(`${url}/stock-opname`, {
        params: {
          keyword,
          columns: ["date", "balance", "image_url"],
          perpage: perpage,
          page,
          sort: sort.sort,
          order: sort.order,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (error.response) {
          message.error(error.response.data.message);
          history.push("/login");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setPerpage(pagination.pageSize);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeItems = (data) => {
    const index = data[data.length - 1];
    setRowItems([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getManifestItems(data2[0]);
    }
  };

  const getManifestItems = (id = selectedId) => {
    setLoadingItems(true);
    axios
      .get(`${url}/stock-opname/details/${id}`, {
        params: {
          keyword: keywordItems,
          columns: [
            "warehouse_name",
            "lemari_name",
            "rack_name",
            "item_name",
            "stock_min",
            "stock_max",
            "quantity",
            "quantity_adjudgement",
            "notes",
          ],
          perpage: perPageDetail,
          page: pageDetail,
        },

        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setManifestItems(res.data.data);
        setManifestItemsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet started");
        }
      })
      .finally(() => {
        setLoadingItems(false);
      });
  };

  const handleChangeItemsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setPerPageDetail(pagination.pageSize);
  };

  return localStorage.getItem("token") ? (
    <div>
      <ul className="breadcumb">
        <li>
          <Link to="/stock-opname">Stock Opname</Link>
        </li>
      </ul>
      <div className="content">
        <Row justify="space-between">
          <Col>
            <Link to="/stock-opname/create">
              <Button
                type="primary"
                style={{ backgroundColor: "#069964", borderColor: "#069964" }}
              >
                <PlusOutlined />
                Add
              </Button>
            </Link>
          </Col>
          <Col xs={24} md={24} lg={6} className="align-right">
            <Input.Search
              style={{ marginTop: 10 }}
              allowClear="true"
              onSearch={(value) => setKeyword(value)}
              placeholder="Search..."
            />
          </Col>
        </Row>
        <Row className="mt-40">
          <Col xs={24}>
            <Table
              expandable={{
                expandedRowRender: getItems,
                onExpandedRowsChange: (data) => handleChangeItems(data),
                expandedRowKeys: rowItems,
              }}
              columns={columns}
              dataSource={data}
              pagination={{ total, showSizeChanger: true }}
              loading={loading}
              rowKey={(data) => data._id}
              onChange={handleTableChange}
            />
          </Col>
        </Row>
      </div>
    </div>
  ) : (
    <Redirect to="/login" />
  );
}

export default StockOpname;
